import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogModal, SaveControl } from 'shared/ui';
import { Group, SubType } from 'shared/models';
import { useGroupItemActions } from '../../hooks';
import { GroupIdCell } from './GroupIdCell';
import { GroupNameCell } from './GroupNameCell';
import { SubtypeCell } from './SubtypeCell';

interface Props {
  groupInfo: Group;
  onDelete: () => void;
  onChangeGroup: (id: string, name: string, legacyGroupId: string | null, subtypeId: string | null) => void;
  subTypes: SubType[];
  isSelected?: boolean;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isLoading: boolean;
  disabled: boolean;
}

export const GroupItem: FC<Props> = ({
  groupInfo,
  onDelete,
  onChangeGroup,
  subTypes,
  isSelected,
  onSelect,
  onKeyDown,
  isLoading,
  disabled,
}) => {
  const { name, legacyGroupId, subtype } = groupInfo;

  const { t } = useTranslation();

  const {
    isConfirm,
    onToggleConfirm,
    nameValue,
    onChangeValue,
    idValue,
    onChangeIdValue,
    nameError,
    idError,
    isNameEditing,
    isIdEditing,
    savingField,
    handleDelete,
    onOpenNameEditing,
    onOpenIdEditing,
    onCancelEditing,
    handleSelect,
    handleSaveName,
    handleSaveLegacyId,
    handleSaveSubType,
  } = useGroupItemActions({
    groupInfo,
    onDelete,
    onSelect,
    onChangeGroup,
    disabled,
  });

  const isShowLoading = isLoading && !isSelected;
  const isNameLoading = isShowLoading && savingField === 'name';
  const isLegacyIdLoading = isShowLoading && savingField === 'legacyId';
  const isSubtypeLoading = isShowLoading && savingField === 'subtype';

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: '100%',
          height: 38,
          borderBottom: '1px solid lightgrey',
          bgcolor: isSelected ? 'rgba(29, 135, 69, 0.08) !important' : '#fff',
          color: disabled ? 'info.dark' : 'black',
          cursor: 'pointer',
          '&:hover': { bgcolor: 'rgba(29, 135, 69, 0.04)' },
        }}
      >
        <GroupIdCell
          idValue={idValue}
          isIdEditing={isIdEditing}
          idError={idError}
          legacyGroupId={legacyGroupId}
          isLegacyIdLoading={isLegacyIdLoading}
          isSelected={isSelected}
          onChangeIdValue={onChangeIdValue}
          handleSaveLegacyId={handleSaveLegacyId}
          onCancelEditing={onCancelEditing}
          onOpenIdEditing={onOpenIdEditing}
          handleSelect={handleSelect}
          onKeyDown={onKeyDown}
        />

        <GroupNameCell
          name={name}
          nameValue={nameValue}
          isNameEditing={isNameEditing}
          isNameLoading={isNameLoading}
          nameError={nameError}
          isSelected={isSelected}
          onCancelEditing={onCancelEditing}
          onChangeValue={onChangeValue}
          handleSaveName={handleSaveName}
          onOpenNameEditing={onOpenNameEditing}
          onToggleConfirm={onToggleConfirm}
          handleSelect={handleSelect}
          onKeyDown={onKeyDown}
        />

        <SubtypeCell
          subtype={subtype}
          disabled={isNameEditing || isIdEditing || disabled}
          isSelected={isSelected}
          subTypes={subTypes}
          isSubtypeLoading={isSubtypeLoading}
          handleSaveSubType={handleSaveSubType}
        />

        {/* Brand column will be added when it is ready on BE */}
        <Stack sx={{ width: '25.5%' }}></Stack>
      </Stack>

      <DialogModal open={isConfirm} onClose={onToggleConfirm} maxWidth="xs">
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
          By deleting the group all group items will be removed, this action cant be undone
        </Typography>

        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>Are you sure ?</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl onSave={handleDelete} onCancel={onToggleConfirm} btnTexts={[t('yes'), t('no')]} />
        </Stack>
      </DialogModal>
    </>
  );
};
