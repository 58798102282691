import React, { FC } from 'react';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  zIndex: '500 !important',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.info.main,
    fontSize: '14px',
    borderRadius: '8px',
    marginTop: '12px !important',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}));

export const ErrorTooltip: FC<TooltipProps> = ({ children, title, ...props }) => {
  return (
    <CustomToolTip title={title} {...props}>
      <Box component="span" sx={props.sx}>
        {children}
      </Box>
    </CustomToolTip>
  );
};
