import { FC } from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Icon, SaveControl } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { Brand, Group, TableProductInfo } from 'shared/models';
import { TableTabs } from './TableTabs';
import { useChangeProductsControl } from '../hooks';

type Props = {
  disabled: boolean;
  type: string;
  specification: number;
  items: TableProductInfo[];
  isEditing: boolean;
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  isDistributionCurveType: boolean;
  isSaveLoading: boolean;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  groups: Group[];
  brands: Brand[];
  allComponents: TableProductInfo[];
};

export const ChangeProductsControl: FC<Props> = ({
  disabled,
  type,
  specification,
  items,
  changedItems,
  isEditing,
  deletedItemIDs,
  newItemIDs,
  isDistributionCurveType,
  isSaveLoading,
  cellErrors,
  groups,
  brands,
  allComponents,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    validationLoading,
    allViewChangedItemsAmount,
    onOpenCatalogFinalUpdates,
    onCancelClick,
    handSaveChanges,
  } = useChangeProductsControl({
    type,
    items,
    changedItems,
    deletedItemIDs,
    newItemIDs,
    isDistributionCurveType,
    cellErrors,
    groups,
    brands,
    allComponents,
  });

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ py: 2, px: 3, bgcolor: '#FFF' }}>
      <TableTabs
        disabled={disabled}
        type={type}
        specification={specification}
        isDistributionCurveType={isDistributionCurveType}
      />

      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack direction="row" alignItems="center" spacing={1} height={48}>
          <Icon path="products-view/catalog-total-items" />

          <Typography
            noWrap
            sx={{ fontSize: 18, fontWeight: 600, lineHeight: '130%', letterSpacing: '0.18px', color: '#242731' }}
          >
            {`${t('total_items')}: ${items?.length}`}
          </Typography>
        </Stack>

        {isEditing && (
          <>
            {!!allViewChangedItemsAmount && (
              <Button
                onClick={onOpenCatalogFinalUpdates}
                sx={{
                  p: 1,
                  gap: 1,
                  borderRadius: '6px',
                  border: '1px solid #DDE0E6',
                  bgcolor: '#F2F4F7',
                }}
              >
                <Icon path="products-view/catalog-view-changes" />

                <Typography noWrap sx={{ fontWeight: 600, color: '#344054', textTransform: 'capitalize' }}>
                  {`${t('View Changes')}: ${allViewChangedItemsAmount}`}
                </Typography>
              </Button>
            )}

            <SaveControl
              small
              onSave={handSaveChanges}
              onCancel={onCancelClick}
              isSaveDisabled={!allViewChangedItemsAmount}
              isLoading={validationLoading || (isDistributionCurveType && isSaveLoading)}
              btnTexts={[t('Save Changes')]}
              sx={{ gap: 3, height: '40px' }}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
