import { Box, MenuItem, Stack } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { separateByUpperCase } from 'shared/lib';
import { ConstantFilter } from 'shared/models';
import { selectCatalogTypes, setCatalogConstantFilters } from 'shared/slices';
import { FilterSelect } from 'shared/ui';

type Props = {
  filters?: ConstantFilter;
  groups: string[];
  subtypes: string[];
  disabled?: boolean;
  isEditing: boolean;
  isSaveLoading: boolean;
};

export const CatalogConstantFilters: FC<Props> = ({
  filters,
  subtypes,
  groups,
  disabled,
  isEditing,
  isSaveLoading,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { catalogId, type, onChangeType } = useCatalogSearchParams();

  const catalogTypes: string[] = useAppSelector(selectCatalogTypes);

  const isNoTypes = !catalogTypes.length;
  const isDisabled = disabled || isEditing || isSaveLoading || isNoTypes;

  const subtypeValue = useMemo(() => {
    const subtype = filters?.subtype;
    return subtype && subtypes.includes(subtype) ? subtype : '';
  }, [filters, subtypes]);

  const groupValue = useMemo(() => {
    const group = filters?.group;
    return group && subtypes.includes(group) ? group : '';
  }, [filters, groups]);

  useEffect(() => {
    onResetFilters('all');
  }, [type]);

  const onChangeFilter = (filterName: 'subtype' | 'group', value: string) => {
    if (filters?.[filterName] === value) return;

    dispatch(setCatalogConstantFilters({ catalogId, filters: { [filterName]: value } }));
  };

  const onResetFilters = (resetType: 'subtype' | 'group' | 'all') => {
    const newFilters: Partial<ConstantFilter> =
      resetType === 'all' ? { subtype: null, group: null } : { [resetType]: null };

    dispatch(setCatalogConstantFilters({ catalogId, filters: newFilters }));
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack direction="row" alignItems="center" spacing={3} sx={{ px: 3, py: 1.5, bgcolor: '#FFF' }}>
        <FilterSelect label={t('type')} value={isNoTypes ? '' : type} disabled={isDisabled}>
          {catalogTypes.map((type) => (
            <MenuItem key={type} value={type} onClick={() => onChangeType(type)}>
              {separateByUpperCase(type)}
            </MenuItem>
          ))}
        </FilterSelect>

        <FilterSelect
          label={t('subtype')}
          value={subtypeValue}
          disabled={isDisabled}
          clearFilter={() => onResetFilters('subtype')}
          allowEmpty
        >
          {subtypes.map((subtype) => (
            <MenuItem key={subtype} value={subtype} onClick={() => onChangeFilter('subtype', subtype)}>
              {subtype}
            </MenuItem>
          ))}
        </FilterSelect>

        <FilterSelect
          label={t('group')}
          value={groupValue}
          disabled={isDisabled}
          clearFilter={() => onResetFilters('group')}
          allowEmpty
        >
          {groups.map((group) => (
            <MenuItem key={group} value={group} onClick={() => onChangeFilter('group', group)}>
              {group}
            </MenuItem>
          ))}
        </FilterSelect>
      </Stack>

      {isEditing && (
        <Box sx={{ position: 'absolute', width: '100%', height: '100%', bgcolor: 'rgba(52, 64, 84, 0.50)' }} />
      )}
    </Stack>
  );
};
