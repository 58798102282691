import { Box, CircularProgress, Grow, Stack, Typography } from '@mui/material';
import { AvailabilityOrgItem } from 'entities/Organization/components/AvailabilityOrgItem';
import { OrgTitle } from 'entities/Organization/components/AvailabilityOrgItem/OrgTitle';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Organization } from 'shared/models';
import { Button, ItemsBox } from 'shared/ui';

interface Props {
  currentOrgId: string;
  onChangeCurrentOrgId: (id: string) => void;
  onOpenAvailability: () => void;
  isLoading: boolean;
  isOpenBtn: boolean;
}

export const CatalogAvailabilityOrgsTable: FC<Props> = ({
  currentOrgId,
  onChangeCurrentOrgId,
  onOpenAvailability,
  isLoading,
  isOpenBtn,
}) => {
  const { t } = useTranslation();

  const [btnCoordY, setBtnCoordY] = useState(0);

  const { organizations } = useAppSelector((st) => st.orgs);

  const onSetBtnCoord = (y: number) => setBtnCoordY(y);

  const renderOrgs = (orgs: Organization[], depth = 0): ReactNode => {
    return orgs.map((org, i) => {
      if (org.subOrganizations.length === 0) {
        return (
          <OrgTitle
            key={org.id}
            org={org}
            depth={depth}
            currentOrgId={currentOrgId}
            onChangeCurrentOrgId={onChangeCurrentOrgId}
            disabled={isLoading}
            onSetBtnCoord={onSetBtnCoord}
          />
        );
      }
      return (
        <AvailabilityOrgItem
          key={org.id}
          org={org}
          depth={depth}
          childOrgs={renderOrgs(org.subOrganizations, depth + 1)}
          isLastChild={orgs.length - 1 === i}
          currentOrgId={currentOrgId}
          onChangeCurrentOrgId={onChangeCurrentOrgId}
          disabled={isLoading}
          onSetBtnCoord={onSetBtnCoord}
          childIDs={org.subOrganizations.map((o) => o.organizationId)}
        />
      );
    });
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <ItemsBox
        sx={{
          width: '280px',
          minWidth: '280px',
          overflowX: 'hidden',
          maxHeight: 'calc(100svh - 310px)',
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Typography
            sx={{
              color: isLoading ? 'info.dark' : 'darksome.main',
              padding: '4px 8px',
              fontWeight: 600,
              textTransform: 'capitalize',
            }}
          >
            {t('Select Organization')}
          </Typography>

          {isLoading && <CircularProgress size={15} color="primary" sx={{ ml: 1 }} />}
        </Stack>

        {renderOrgs(organizations)}
      </ItemsBox>

      {!isLoading && (
        <Box sx={{ position: 'fixed', top: `${btnCoordY}px`, left: '310px', ml: 2 }}>
          <Grow in={isOpenBtn}>
            <div>
              <Button onClick={onOpenAvailability} filled sx={{ borderRadius: '14px', height: '48px' }}>
                {`+ ${t('Create New Availability')}`}
              </Button>
            </div>
          </Grow>
        </Box>
      )}
    </Box>
  );
};
