import { IconButton, InputBase, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { ArrowDown, Icon, SearchIcon } from 'shared/ui';
import { SearchData } from '../hooks';

type Props = {
  searchQuery: string;
  searchData?: SearchData;
  onChange: (newValue: string) => void;
  goToMatch: (direction: 'prev' | 'next') => void;
};

export const TranslationsSearchInput: FC<Props> = ({ searchQuery, searchData, onChange, goToMatch }) => {
  const { matches, currentMatchIdx = 0 } = searchData ?? {};

  const matchesCount = matches?.length;
  const disableArrow = matchesCount === 1;
  const color = disableArrow ? 'rgba(114, 114, 114, 0.7)' : 'rgba(114, 114, 114, 1)';

  return (
    <Stack direction="row" alignItems="center" ml="auto">
      <InputBase
        value={searchQuery}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Find..."
        startAdornment={<SearchIcon sx={{ fontSize: 16, mr: 1 }} />}
        endAdornment={
          matchesCount ? (
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: color }}>{`(${currentMatchIdx + 1}/${matchesCount})`}</Typography>

              <IconButton
                onClick={() => goToMatch('next')}
                disabled={disableArrow}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0 }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
              <IconButton
                onClick={() => goToMatch('prev')}
                disabled={disableArrow}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0, rotate: '180deg' }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
            </Stack>
          ) : null
        }
        sx={{
          position: 'relative',
          p: 1,
          width: searchQuery.length ? 320 : 100,
          height: 33,
          borderRadius: '6px',
          border: '1px solid #DDE0E6',
          bgcolor: '#FFF',
          zIndex: 2,
          '& .MuiInputBase-input': {
            p: 0,
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#757575',
            opacity: 1,
          },
          '&:focus-within': {
            width: 320,
          },
          transition: 'width 0.3s ease-in-out',
        }}
      />

      {searchQuery && (
        <IconButton onClick={() => onChange('')} sx={{ width: 40, height: 40 }}>
          <Icon path="products-view/search-close" />
        </IconButton>
      )}
    </Stack>
  );
};
