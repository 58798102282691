import {
  BulkElement,
  CatalogComponent,
  CellType,
  FullSpecificationsProductInfo,
  TableProductInfo,
  Brand,
  Group,
  MovedProductGroupDTO,
  ConstantFilter,
  TableFilter,
} from 'shared/models';
import { tableCellValidation, validateStringLength } from './tableCellValidation';
import { PRODUCT_KEY, SORTING } from 'shared/constants';
import { sortDistributionCurves } from './distributionCurves';
import { newProductsDtoFactory, tableProductFactory, updatedProductsDtoFactory } from './factories';

export const getProductsMap = (items: TableProductInfo[]) => {
  return items.reduce(
    (obj, product) => {
      obj[product.id] = product;
      return obj;
    },
    {} as { [id: string]: TableProductInfo }
  );
};

export const getTableProducts = (
  fetchedProducts: FullSpecificationsProductInfo[],
  type: string
): TableProductInfo[] => {
  return fetchedProducts.map((fp) => tableProductFactory(type, fp));
};

export const getCurrentViewCells = (
  cells: CellType[],
  initItems: TableProductInfo[],
  changedItems: { [id: string]: TableProductInfo }
): CellType[] => {
  return cells.map((c) => {
    const field = c.field as keyof TableProductInfo;

    const changedItem = changedItems[c.id];

    if (changedItem && field in changedItem) {
      return { ...c, value: changedItem[field] } as CellType;
    }

    const initItem = initItems.find((prod) => prod.id === c.id) as TableProductInfo;

    if (initItem && field in initItem) {
      return { ...c, value: initItem[field] } as CellType;
    } else {
      return { ...c } as CellType;
    }
  });
};

export const getCurrentViewProducts = (
  initItems: { [key: string]: TableProductInfo },
  changedItems: { [key: string]: TableProductInfo }
) => {
  return Object.keys(initItems ?? {}).map((id) => {
    const changedItem = changedItems[id];
    const initItem = initItems[id];

    if (changedItem) {
      return { ...initItem, ...changedItem } as TableProductInfo;
    }
    return initItem;
  });
};

export const checkIsUniqCellValue = (
  id: string,
  field: string,
  value: string,
  allComponents: TableProductInfo[]
) => {
  if (/* field === PRODUCT_KEY.SKU ||  */ field === PRODUCT_KEY.DESC) {
    let isUniq = true;

    for (const component of allComponents) {
      const isSameCell = id === component.id;

      if (value === component[field] && !isSameCell) {
        isUniq = false;
      }
    }

    if (!isUniq) {
      /* if (field === PRODUCT_KEY.SKU) {
        return 'This SKU already exists in this catalog. SKU must be unique';
      } */
      if (field === PRODUCT_KEY.DESC) {
        return 'This description already exists in this catalog. Description must be unique';
      }
    }

    return '';
  }
};

export const checkIsGroupValueExist = (value: string, groups: Group[]) => {
  const foundGroup = groups.find((group) => group.name === value);

  return foundGroup ? '' : 'The value is invalid. Select something';
};

export const validateCellValue = (
  id: string,
  field: string,
  value: string,
  itemsMap: { [id: string]: TableProductInfo },
  deletedIDs: { [id: string]: boolean },
  isDistributionCurve: boolean,
  changedItems: { [id: string]: TableProductInfo },
  allComponents: TableProductInfo[],
  groups: Group[] = []
) => {
  const key = field as keyof typeof tableCellValidation;

  const validate = tableCellValidation[key] as (value: any) => string;
  const error = validate(value);

  for (const id in deletedIDs) {
    delete itemsMap[id];
  }

  let isUniqError = undefined;

  if (!isDistributionCurve) {
    isUniqError = checkIsUniqCellValue(id, field, value as string, allComponents);
  }

  let isGroupError = undefined;

  if (field === PRODUCT_KEY.GROUP && groups.length) {
    isGroupError = checkIsGroupValueExist(value as string, groups);
  }

  return error || isUniqError || isGroupError;
};

export const makeDeepValidation = (
  items: TableProductInfo[],
  deletedItemIDs: { [id: string]: boolean },
  isDistributionCurve: boolean,
  changedItems: { [id: string]: TableProductInfo },
  allComponents: TableProductInfo[],
  groups: Group[]
): { [id: string]: { [key: string]: boolean } } | null => {
  items = items.filter((i) => !deletedItemIDs[i.id]);

  const itemsMap = getProductsMap(items);

  const cellErrors: { [id: string]: { [key: string]: boolean } } = {};

  const noValidateKeys: string[] = [
    PRODUCT_KEY.ID,
    PRODUCT_KEY.GROUP_ID,
    PRODUCT_KEY.BRAND,
    PRODUCT_KEY.BRAND_ID,
    PRODUCT_KEY.SUBTYPE,
    PRODUCT_KEY.STRING_RESOURCES,
  ];
  const noValidateDCKeys: string[] = [
    PRODUCT_KEY.SKU,
    PRODUCT_KEY.NOZZLE,
    PRODUCT_KEY.SWIVEL,
    PRODUCT_KEY.FATHER_ID,
  ];

  for (const id in itemsMap) {
    const product = itemsMap[id];

    const cells = Object.entries(product)
      .filter(([key]) => !noValidateKeys.includes(key))
      .filter(([key]) => (isDistributionCurve ? !noValidateDCKeys.includes(key) : true));

    delete itemsMap[id];

    for (let j = 0; j < cells.length; j++) {
      const [field, value] = cells[j];

      const error = validateCellValue(
        id,
        field,
        value as string,
        itemsMap,
        deletedItemIDs,
        isDistributionCurve,
        changedItems,
        allComponents,
        groups
      );

      if (!error) continue;

      if (cellErrors[id]) {
        cellErrors[id][field] = true;
      } else {
        cellErrors[id] = { [field]: true };
      }
    }
  }

  // for (let i = 0; i < items.length; i++) {
  //   const product = items[i];

  //   const cells = Object.entries(product)
  //     .filter(([key]) => !noValidateKeys.includes(key))
  //     .filter(([key]) => (isDistributionCurve ? !noValidateDCKeys.includes(key) : true));

  //   for (let j = 0; j < cells.length; j++) {
  //     const [field, value] = cells[j];
  //     const id = product.id;

  //     const error = validateCellValue(id, field, value as string, items, deletedItemIDs);

  //     if (!error) continue;

  //     if (cellErrors[id]) {
  //       cellErrors[id][field] = true;
  //     } else {
  //       cellErrors[id] = { [field]: true };
  //     }
  //   }
  // }

  return Object.keys(cellErrors).length ? cellErrors : null;
};

export const validateTranslationValue = (
  value: string,
  allValues: string[],
  type: 'Description' | 'Translation'
) => {
  const isLengthInvalid = validateStringLength(value, 100, 2);

  if (isLengthInvalid) {
    return isLengthInvalid;
  }

  let isUniq = true;

  for (const item of allValues) {
    if (value === item) {
      isUniq = false;
    }
  }

  if (!isUniq) {
    return `This ${type.toLowerCase()} already exists in this catalog. ${type} must be unique`;
  }

  return '';
};

export const sortProducts = (
  item1: TableProductInfo,
  item2: TableProductInfo,
  field: string,
  sorting: string
): number => {
  const value1 = item1[field as keyof TableProductInfo];
  const value2 = item2[field as keyof TableProductInfo];

  if (sorting === SORTING.ASC) {
    if (value1! > value2!) return 1;
    if (value1! < value2!) return -1;
  } else {
    if (value1! > value2!) return -1;
    if (value1! < value2!) return 1;
  }
  return 0;
};

export const getDeletedProductsDTO = (delIDs: string[], items: TableProductInfo[]) => {
  const dtos: { id: string; groupId: string }[] = delIDs.map((id) => {
    const groupId = items.find((item) => item.id === id)?.groupId;

    return { id, groupId: groupId as string };
  });
  return dtos;
};

export const getNewProductsDTO = (params: {
  type: string;
  newItemIDs: string[];
  items: TableProductInfo[];
  groups: Group[];
  brands: Brand[];
}) => {
  const { type, newItemIDs, items, groups, brands } = params;

  const newItems = items?.filter(({ id }) => newItemIDs?.includes(id));

  const DTOs: { id: string; groupId: string; dto: CatalogComponent }[] = newItems.map((p) => {
    const { id, sku, description, ...otherPayloadChanges } = p as TableProductInfo;

    const groupId = groups.find((g) => g.name === p.group)?.id ?? '';
    const brandId = brands.find((b) => b.name === p.brand)?.id ?? null;

    const newComponent = newProductsDtoFactory(type, otherPayloadChanges);

    const dto = { sku, description, brandId, ...newComponent } as CatalogComponent;

    return { id, groupId, dto };
  });

  return DTOs;
};

export const getUpdatedProductsDTO = (params: {
  type: string;
  changedProducts: TableProductInfo[];
  items: TableProductInfo[];
  groups: Group[];
  brands: Brand[];
}) => {
  const { type, changedProducts, items, groups, brands } = params;

  const movedProductGroups: MovedProductGroupDTO[] = [];

  const changedItemDTOs: { id: string; groupId: string; dto: CatalogComponent }[] = changedProducts.map((p) => {
    const { id, ...otherPayloadChanges } = p as TableProductInfo;
    const productItem = items.find((item) => item.id === id) as TableProductInfo;

    const groupId = productItem.groupId as string;

    const targetGroupId = groups.find((g) => g.name === p.group)?.id ?? '';
    if (targetGroupId && groupId !== targetGroupId) {
      const moveDTO: MovedProductGroupDTO = { componentId: id, groupId, targetGroupId };
      movedProductGroups.push(moveDTO);
    }

    const brandId = brands.find((b) => b.name === productItem.brand)?.id ?? null;

    const newComponent = updatedProductsDtoFactory(type, otherPayloadChanges, productItem);

    const dto = {
      ...newComponent,
      sku: otherPayloadChanges['sku'] ?? productItem['sku'],
      description: otherPayloadChanges['description'] ?? productItem['description'],
      brandId,
    } as CatalogComponent;

    return { id, groupId, dto };
  });

  return { changedItemDTOs, movedProductGroups };
};

export const getProductsSaveBulk = (params: {
  type: string;
  items: TableProductInfo[];
  newItemIDs: { [id: string]: boolean };
  deletedItemIDs: { [id: string]: boolean };
  changedItems: { [id: string]: TableProductInfo };
  groups: Group[];
  brands: Brand[];
}) => {
  const { type, items, newItemIDs, deletedItemIDs, changedItems, groups, brands } = params;

  const bulk: { bulkElement: BulkElement; item: TableProductInfo }[] = [];

  const deletedIDs = Object.keys(deletedItemIDs);
  const delItemsDTOs = getDeletedProductsDTO(deletedIDs, items);
  delItemsDTOs.forEach(({ id, groupId }) => {
    const itemRef = items.findIndex((item) => item.id === id);
    const item = items.find((item) => item.id === id) as TableProductInfo;
    const bulkElement: BulkElement = {
      itemRef,
      operation: 'Delete',
      groupId,
      componentId: id,
    };
    bulk.push({ item, bulkElement });
  });

  const newDtoParams = { type, newItemIDs: Object.keys(newItemIDs), items, groups, brands };

  const newItemDTOs = getNewProductsDTO(newDtoParams);
  newItemDTOs.forEach((p) => {
    const { id, groupId, dto } = p;
    const itemRef = items.findIndex((item) => item.id === id);
    const item = items.find((item) => item.id === id) as TableProductInfo;

    const bulkElement: BulkElement = {
      itemRef,
      operation: 'Create',
      groupId,
      componentData: dto,
    };

    bulk.push({ item, bulkElement });
  });

  const changedProducts = Object.values(changedItems).filter(({ id }) => !newItemIDs[id] && !deletedItemIDs[id]);

  const updatingDtoParams = { type, changedProducts, items, groups, brands };
  const { changedItemDTOs, movedProductGroups } = getUpdatedProductsDTO(updatingDtoParams);

  changedItemDTOs.forEach((p) => {
    const { id, groupId, dto } = p;
    const itemRef = items.findIndex((item) => item.id === id);
    const item = items.find((item) => item.id === id) as TableProductInfo;

    const bulkElement: BulkElement = {
      itemRef,
      operation: 'Update',
      groupId,
      componentData: dto,
      componentId: id,
    };

    bulk.push({ item, bulkElement });
  });

  return { bulk, movedProductGroups };
};

export const getFilteredItems = (
  items: TableProductInfo[],
  filters: TableFilter[],
  sorting?: { field: string; value: SORTING }
) => {
  let filteredItems = items.filter((item) => isItemMatchingFilters(item, filters));

  const isDistributionCurve = !!filteredItems?.[0]?.fatherEmitterId;

  if (sorting) {
    if (isDistributionCurve) {
      filteredItems = sortDistributionCurves(filteredItems, sorting.field, sorting.value);
    } else {
      filteredItems.sort((p1, p2) => sortProducts(p1, p2, sorting.field, sorting.value));
    }
  }

  const filteredIDs = filteredItems.map(({ id }) => id);
  const hidedItems = items.filter(({ id }) => !filteredIDs.includes(id));

  return { visibleItems: filteredItems, hidedItems };
};

export const getColumnFilters = (
  items: TableProductInfo[],
  currentField: string,
  filters: TableFilter[],
  constantFilters: ConstantFilter
) => {
  // if 'subtype' or 'group' constant filters are applied,
  // the same table filters should contain only selected option
  if (currentField === 'subtype' || currentField === 'group') {
    const constantFilterValue = constantFilters[currentField];
    if (constantFilterValue) return [constantFilterValue];
  }

  // to get all available filter options for a column,
  // items should be filtered by all columns except the current one
  const relevantFilters = filters.filter(({ field }) => field !== currentField);

  const filteredItems = items.filter((item) => isItemMatchingFilters(item, relevantFilters));

  return [...new Set(filteredItems.map((item) => item[currentField as keyof TableProductInfo] as string))];
};

const isItemMatchingFilters = (item: TableProductInfo, filters: TableFilter[]) => {
  return filters.every((filter) => {
    const key = filter?.field as keyof TableProductInfo;
    const itemValue = item[key]?.toString() ?? '';
    const filterValues = new Set(filter?.values.map((v) => v?.toString() ?? ''));
    return filterValues.has(itemValue);
  });
};
