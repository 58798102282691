import { ChangeEvent, useState } from 'react';
import { Group } from 'shared/models';

type Args = {
  groupInfo: Group;
  onDelete: () => void;
  onChangeGroup: (id: string, name: string, legacyGroupId: string | null, subtypeId: string | null) => void;
  onSelect: (id: string) => void;
  disabled: boolean;
};

export const useGroupItemActions = ({ groupInfo, onDelete, onSelect, onChangeGroup, disabled }: Args) => {
  const { id, name, legacyGroupId, subtype } = groupInfo;

  const [isConfirm, setIsConfirm] = useState(false);
  const onToggleConfirm = () => setIsConfirm((c) => !c);

  const [nameValue, setNameValue] = useState<string>(name);
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);
  const [idValue, setIdValue] = useState<string>(legacyGroupId ?? '');
  const onChangeIdValue = (e: ChangeEvent<HTMLInputElement>) => setIdValue(e.target.value);

  const nameError = nameValue.length < 3 || nameValue.length > 50;
  const idError = idValue?.length > 7;

  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isIdEditing, setIsIdEditing] = useState(false);
  const [savingField, setSavingField] = useState<'legacyId' | 'name' | 'subtype'>('name');

  const handleDelete = () => {
    onToggleConfirm();
    onDelete();
  };
  const onOpenNameEditing = () => {
    setIsNameEditing(true);
    onSelect(id);
    setNameValue(name);
  };
  const onOpenIdEditing = () => {
    setIsIdEditing(true);
    onSelect(id);
    setIdValue(legacyGroupId as string);
  };
  const onCancelEditing = () => {
    setIsNameEditing(false);
    setIsIdEditing(false);

    onSelect('');
    setNameValue(name);
    setIdValue(legacyGroupId as string);
  };

  const handleSelect = () => {
    if (disabled || isNameEditing || isIdEditing) return;
    onSelect(id);
  };

  const handleSaveName = () => {
    if (!nameError && nameValue !== name) {
      setSavingField('name');
      onChangeGroup(id, nameValue, legacyGroupId || null, subtype?.id ?? null);
    }

    setIsNameEditing(false);
    setNameValue(name);
  };
  const handleSaveLegacyId = () => {
    if (!idError && idValue !== legacyGroupId) {
      setSavingField('legacyId');
      onChangeGroup(id, name, idValue, subtype?.id ?? null);
    }

    setIsIdEditing(false);
    setIdValue(legacyGroupId as string);
  };

  const handleSaveSubType = (subtypeId: string | null) => {
    onSelect('');
    if (subtype?.id === subtypeId) return;
    setSavingField('subtype');

    onChangeGroup(id, name, legacyGroupId || null, subtypeId);
    setIsNameEditing(false);
  };

  return {
    isConfirm,
    onToggleConfirm,
    nameValue,
    onChangeValue,
    idValue,
    onChangeIdValue,
    nameError,
    idError,
    isNameEditing,
    isIdEditing,
    savingField,
    handleDelete,
    onOpenNameEditing,
    onOpenIdEditing,
    onCancelEditing,
    handleSelect,
    handleSaveName,
    handleSaveLegacyId,
    handleSaveSubType,
  };
};
