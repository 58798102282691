import { axiosBase } from 'shared/axios';
import { BaseResponse, SubType } from 'shared/models';
import { requestWrapper } from 'shared/lib';

export class SubtypeService {
  static async createSubtype(catalogId: string, body: { subtypeName: string; type: string }) {
    const { data } = await requestWrapper(
      axiosBase.post<BaseResponse<string[]>>(`/Catalogs/${catalogId}/subtypes`, [body])
    );
    if (data) {
      const id = data[0];
      const newSubtype: SubType = { id, name: body.subtypeName, type: body.type };
      return newSubtype;
    }
  }

  static async changeSubtype(
    catalogId: string,
    id: string,
    updateData: { subtypeName: string; isLateral: boolean; isSubMain: boolean; isMainPipe: boolean }
  ) {
    const { error } = await requestWrapper(axiosBase.put(`/Catalogs/${catalogId}/subtypes/${id}`, updateData));
    if (!error) return true;
  }

  static async deleteSubtype(catalogId: string, id: string) {
    const params = { data: [id] };
    const { error } = await requestWrapper(axiosBase.delete(`/Catalogs/${catalogId}/subtypes`, params));
    if (!error) return true;
  }
}
