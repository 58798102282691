import { useState, useMemo, useEffect } from 'react';
import { useCatalogsControl } from 'shared/hooks';
import { StringResource, TableProductInfo } from 'shared/models';

type Args = {
  isProductsLoading: boolean;
  products: TableProductInfo[];
  selectedLanguageId: string;
  updateProduct: (updatedComponent: TableProductInfo) => void;
};

export const useCatalogTranslationsTable = ({
  isProductsLoading,
  selectedLanguageId,
  products,
  updateProduct,
}: Args) => {
  const { allComponents } = useCatalogsControl();
  const [currentProducts, setCurrentProducts] = useState(allComponents);

  const areSearchInputsShown = !isProductsLoading && products.length > 0;

  const descriptions = useMemo(() => {
    if (!areSearchInputsShown) return [];

    return products.map((p) => p.description).filter((desc): desc is string => Boolean(desc));
  }, [products, areSearchInputsShown]);

  const translations = useMemo(() => {
    if (!areSearchInputsShown) return [];
    if (!selectedLanguageId) return [];

    const stringResources = products
      .flatMap((p) => p.stringResources)
      .filter((sr): sr is StringResource => Boolean(sr));
    const currentTranslationItems = stringResources
      .filter((sr) => sr?.languageId === selectedLanguageId)
      .map((sr) => sr?.value);

    return currentTranslationItems;
  }, [products, selectedLanguageId, areSearchInputsShown]);

  const onUpdateProduct = (updatedComponent: TableProductInfo) => {
    setCurrentProducts((prev) =>
      prev.map((product) => (product.id === updatedComponent.id ? updatedComponent : product))
    );
    updateProduct(updatedComponent);
  };

  useEffect(() => {
    setCurrentProducts(allComponents);
  }, [allComponents]);

  return { areSearchInputsShown, descriptions, translations, currentProducts, onUpdateProduct };
};
