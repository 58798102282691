import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from '../../../shared/hooks';
import { axiosBase } from 'shared/axios';
import { BaseResponse, Group, LanguageData, TableProductInfo } from '../../../shared/models';
import {
  selectCatalogLanguage,
  selectCatalogLanguages,
  selectCatalogTypes,
  selectCurrentCatalog,
  selectOpenedCatalogs,
  setLanguage,
  setLanguages,
} from 'shared/slices';
import { requestWrapper } from 'shared/lib';
import { CatalogService } from 'shared/services';

export type GroupExtended = Group & {
  productsCount: number;
};

export const useTranslations = () => {
  const dispatch = useAppDispatch();
  const catalogTypes = useAppSelector(selectCatalogTypes);
  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const languages = useAppSelector(selectCatalogLanguages);
  const selectedLanguageId = useAppSelector(selectCatalogLanguage);
  const openedCatalogs = useAppSelector(selectOpenedCatalogs);

  const { type, onChangeManageType } = useCatalogSearchParams();

  const [isLanguagesLoading, setIsLanguagesLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);

  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedSubtypeId, setSelectedSubtypeId] = useState('');
  const [selectedTranslationId, setSelectedTranslationId] = useState('');

  const [products, setProducts] = useState<TableProductInfo[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<TableProductInfo[]>([]);

  const [activeBox, setActiveBox] = useState<'' | 'group' | 'translation'>('');
  const selectedItemRef = useRef<HTMLElement>(null);

  const subtypeItems = useMemo(
    () => currentCatalog?.subtypes?.filter((st) => st.type === type) ?? [],
    [currentCatalog, type]
  );

  const groupItems = useMemo(() => {
    const groups = currentCatalog?.groups?.filter((g) => g.type === type) ?? [];

    if (!selectedSubtypeId) return groups;
    return groups.filter((g) => g.subtype?.id === selectedSubtypeId);
  }, [currentCatalog, type, selectedSubtypeId]);

  const groupItemsExtended = useMemo(() => {
    const groupsWithProductsCount = groupItems.map((g) => {
      const productsCount = products.length ? products.filter((p) => p.groupId === g.id).length : 0;
      return { ...g, productsCount };
    });

    return groupsWithProductsCount;
  }, [groupItems, products.length]);

  const currentOpenedCatalog = useMemo(
    () => openedCatalogs.find((oc) => oc.catalogId === currentCatalog.id),
    [currentCatalog, openedCatalogs]
  );

  const catalogItems = useMemo(() => {
    return currentOpenedCatalog?.visibleItems ?? [];
  }, [currentOpenedCatalog, type, selectedGroupId]);

  const onLanguageChange = (langId: string) => {
    dispatch(setLanguage(langId));
  };

  const onGroupChange = (groupId: string) => {
    setSelectedGroupId(groupId);

    const filteredProductsByGroup = products.filter((p) => p.groupId === groupId);

    setFilteredProducts(filteredProductsByGroup);
  };

  const onSelect = (id: string) => setSelectedTranslationId(id);

  useEffect(() => {
    // fetch translations only when type or catalog is changed
    if (!currentCatalog.id || !type) return;

    fetchProducts(currentCatalog.id, type);
  }, [type, currentCatalog.id]);

  useEffect(() => {
    if (selectedSubtypeId) setSelectedSubtypeId('');
  }, [type]);

  useEffect(() => {
    // reset selected group when groups array is changed
    // and it doesn't contain the selected group anymore
    if (selectedGroupId) {
      const isSelectedGroupExist = groupItems.find((g) => g.id === selectedGroupId);
      if (isSelectedGroupExist) return;

      setSelectedGroupId('');
    }
  }, [groupItems]);

  const onArrowKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>,
    boxType: '' | 'group' | 'translation'
  ) => {
    if (!boxType) return;
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') return;

    e.preventDefault();

    const currentItems = boxType === 'group' ? groupItems : filteredProducts;
    const itemsIds = currentItems.map((item) => item.id);

    const currentItemId = boxType === 'group' ? selectedGroupId : selectedTranslationId;
    const currentItemIndex = itemsIds.findIndex((id) => id === currentItemId);

    if (currentItemIndex === -1) return;

    const prevIndex = Math.max(0, currentItemIndex - 1);
    const nextIndex = Math.min(itemsIds.length - 1, currentItemIndex + 1);
    const newItemIndex = e.key === 'ArrowDown' ? nextIndex : prevIndex;

    if (newItemIndex === currentItemIndex) return;

    const newItemId = itemsIds[newItemIndex];
    boxType === 'group' ? onGroupChange(newItemId) : setSelectedTranslationId(newItemId);
  };

  const onKeyDownWithScroll = (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
    onArrowKeyPress(e, activeBox);

    if (selectedItemRef.current) {
      const target = selectedItemRef.current;
      const parent = target.parentNode as HTMLElement;

      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        if (e.key === 'ArrowDown') {
          parent.scrollTop -= parentRect.top - targetRect.top;
        }

        if (e.key === 'ArrowUp') {
          parent.scrollTop += targetRect.bottom - parentRect.bottom;
        }
      }
    }
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      setIsLanguagesLoading(true);

      const { data } = await requestWrapper(axiosBase.get<BaseResponse<LanguageData[]>>(`/Language`));
      if (data) {
        dispatch(setLanguages(data));
        dispatch(setLanguage(data[0].id));
      }
      setIsLanguagesLoading(false);
    };

    if (!languages.length) {
      fetchLanguages();
    }
  }, []);

  const fetchProducts = async (catalogId: string, type: string) => {
    setIsProductsLoading(true);

    const products = (await CatalogService.getProducts(catalogId, type)) ?? [];

    setIsProductsLoading(false);
    if (!products.length) return;

    setProducts(products);
  };

  const updateFilteredProduct = (updatedComponent: TableProductInfo) => {
    const newArray = filteredProducts.map((item) => {
      return item.id === updatedComponent.id ? updatedComponent : item;
    });

    setFilteredProducts(newArray);
  };

  return {
    groupItems,
    groupItemsExtended,
    languages,
    isLanguagesLoading,
    isProductsLoading,
    selectedTranslationId,
    selectedGroupId,
    selectedSubtypeId,
    selectedLanguageId,
    type,
    catalogTypes,
    subtypeItems,
    filteredProducts,
    catalogItems,
    onLanguageChange,
    onGroupChange,
    onSelect,
    onChangeManageType,
    updateFilteredProduct,
    selectedItemRef,
    onKeyDownWithScroll,
    setActiveBox,
    setSelectedSubtypeId,
  };
};
