import { ChangeEvent, useState } from 'react';
import { SubType } from 'shared/models';

type Args = {
  subTypeInfo: SubType;
  disabled: boolean;
  onSelect: (id: string) => void;
  onDelete: () => void;
  onChangeSubtype: (
    id: string,
    updateData: {
      subtypeName: string;
      isLateral: boolean;
      isSubMain: boolean;
      isMainPipe: boolean;
    }
  ) => void;
};

export const useSubTypeItemActions = ({ subTypeInfo, disabled, onSelect, onDelete, onChangeSubtype }: Args) => {
  const { id, name, isLateral, isMainPipe, isSubMain } = subTypeInfo;

  const [isConfirm, setIsConfirm] = useState(false);
  const onToggleConfirm = () => setIsConfirm((c) => !c);

  const [nameValue, setNameValue] = useState<string>(name);
  const [includedInOptions, setIncludedInOptions] = useState({
    isLateral: isLateral ?? false,
    isMainPipe: isMainPipe ?? false,
    isSubMain: isSubMain ?? false,
  });
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);

  const [isEdit, setIsEdit] = useState(false);

  const checkboxOptions = [
    { name: 'isLateral', value: includedInOptions.isLateral, label: 'Lateral' },
    { name: 'isSubMain', value: includedInOptions.isSubMain, label: 'Submain' },
    { name: 'isMainPipe', value: includedInOptions.isMainPipe, label: 'Main Pipe' },
  ];

  const onOpenEdit = () => {
    setIsEdit(true);
    onSelect(id);
    setNameValue(name);
  };
  const onCancelEdit = () => {
    setIsEdit(false);
    onSelect('');
    setNameValue(name);
  };

  const handleDelete = () => {
    onToggleConfirm();
    onDelete();
  };
  const handleSelect = (e: any) => {
    e.stopPropagation();
    if (disabled || isEdit) return;
    onSelect(id);
  };

  const handleSaveNameEditing = () => {
    if (nameValue === name) return;
    const updateData = {
      subtypeName: nameValue,
      ...includedInOptions,
    };
    onChangeSubtype(id, updateData);
    setIsEdit(false);
  };

  const toggleIncludedInOptions = (optionName: string, optionValue: boolean) => {
    const updateData = {
      subtypeName: nameValue,
      ...includedInOptions,
      [optionName]: !optionValue,
    };
    onChangeSubtype(id, updateData);
    setIncludedInOptions((prev) => ({ ...prev, [optionName]: !optionValue }));
  };

  return {
    isConfirm,
    onToggleConfirm,
    nameValue,
    onChangeValue,
    isEdit,
    checkboxOptions,
    onOpenEdit,
    onCancelEdit,
    handleDelete,
    handleSelect,
    handleSaveNameEditing,
    toggleIncludedInOptions,
  };
};
