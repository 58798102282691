import { useState } from 'react';
import { useAppDispatch } from 'shared/hooks';
import {
  saveDistributionCurvesThunk,
  setCellErrors,
  setSaveBulk,
  stopEditing,
  toggleCatalogFinalUpdates,
  toggleCatalogSavingStatus,
  toggleValidating,
} from 'shared/slices';
import { getDistributionCurveDTOs, getProductsSaveBulk, isTableRowEmpty, makeDeepValidation } from 'shared/lib';
import { useParams } from 'react-router-dom';
import { Brand, Group, TableProductInfo } from 'shared/models';

type ProductsData = {
  type: string;
  items: TableProductInfo[];
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  isDistributionCurveType: boolean;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  groups: Group[];
  brands: Brand[];
  allComponents: TableProductInfo[];
};

export const useChangeProductsControl = (productsData: ProductsData) => {
  const {
    type,
    items,
    changedItems,
    deletedItemIDs,
    newItemIDs,
    isDistributionCurveType,
    cellErrors,
    groups,
    brands,
    allComponents,
  } = productsData;

  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();

  const [validationLoading, setValidationLoading] = useState(false);

  const allViewChangedItemsAmount = [
    ...Object.keys(newItemIDs ?? {}),
    ...Object.keys(deletedItemIDs ?? {}),
    ...Object.keys(changedItems ?? {}).filter((id) => !newItemIDs[id]),
  ].length;

  const onOpenCatalogFinalUpdates = () => {
    setTimeout(() => {
      dispatch(toggleCatalogFinalUpdates());
    }, 0);
  };

  const onCancelClick = () => {
    setTimeout(() => {
      catalogId && dispatch(stopEditing(catalogId));
    }, 0);
  };

  const handSaveChanges = async () => {
    if (!catalogId) return;

    if (!Object.keys(changedItems).length && !Object.keys(deletedItemIDs).length) {
      onCancelClick();
      return;
    }

    setValidationLoading(true);

    setTimeout(async () => {
      const hasErrors = !!Object.keys(cellErrors ?? {}).length;

      const nonEmptyItems = items.filter((item) => !isTableRowEmpty(item, isDistributionCurveType));

      const errors = makeDeepValidation(
        nonEmptyItems,
        deletedItemIDs,
        isDistributionCurveType,
        changedItems,
        allComponents,
        groups
      );

      if (errors || hasErrors) {
        errors && dispatch(setCellErrors({ catalogId, cellErrors: errors }));
        dispatch(toggleValidating(catalogId));

        setValidationLoading(false);

        return;
      }

      setValidationLoading(false);

      if (isDistributionCurveType) {
        const curveOptions = { items: nonEmptyItems, changedItems, newItemIDs, deletedItemIDs };
        const { newCurves, changedCurves, deletedCurves } = getDistributionCurveDTOs(curveOptions);

        catalogId &&
          (await dispatch(saveDistributionCurvesThunk({ catalogId, newCurves, changedCurves, deletedCurves })));

        return;
      }

      const bulkOptions = {
        type,
        items: nonEmptyItems,
        newItemIDs,
        deletedItemIDs,
        changedItems,
        groups,
        brands,
      };

      const { bulk, movedProductGroups } = getProductsSaveBulk(bulkOptions);

      catalogId && dispatch(setSaveBulk({ catalogId, bulk, movedProductGroups }));

      dispatch(toggleCatalogSavingStatus());
      setValidationLoading(false);
    }, 0);
  };

  return {
    validationLoading,
    allViewChangedItemsAmount,
    onOpenCatalogFinalUpdates,
    onCancelClick,
    handSaveChanges,
  };
};
