import { FC, KeyboardEvent, RefObject } from 'react';
import { ItemsBox } from 'shared/ui';
import { BoxHeader } from '../GroupsSubtypesBrands';
import { ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GroupExtended } from 'widgets/CatalogManager/hooks';

type Props = {
  selectedGroupId: string;
  groupItems: GroupExtended[];
  disabled?: boolean;
  onGroupChange: (groupId: string) => void;
  onKeyDown: (e: KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => void;
  selectedItemRef: RefObject<HTMLElement>;
  setActiveBox: () => void;
};

export const GroupsTable: FC<Props> = ({
  selectedGroupId,
  groupItems,
  disabled,
  onGroupChange,
  onKeyDown,
  setActiveBox,
  selectedItemRef,
}) => {
  const { t } = useTranslation();

  return (
    <ItemsBox sx={{ width: 320, minWidth: 320 }}>
      <BoxHeader
        sx={{
          position: 'sticky',
          top: 0,
          height: 48,
          zIndex: 2,
          bgcolor: '#FFF',
          borderBottom: '0.5px solid #D4D4D4',
        }}
      >
        {t('Select Group')}
      </BoxHeader>

      {groupItems.map(({ id, name, productsCount }) => (
        <ButtonBase
          key={id}
          ref={id === selectedGroupId ? (selectedItemRef as RefObject<HTMLButtonElement>) : null}
          disabled={disabled}
          onClick={() => {
            onGroupChange(id);
            setActiveBox();
          }}
          onKeyDown={onKeyDown}
          tabIndex={0}
          sx={{
            justifyContent: 'space-between',
            gap: 1,
            py: 1,
            pl: 1.5,
            pr: 2.5,
            height: 38,
            borderBottom: '0.5px solid #D4D4D4',
            textTransform: 'capitalize',
            ...(selectedGroupId === id && { bgcolor: 'rgba(29, 135, 69, 0.08) !important' }),
            '&:hover': {
              bgcolor: 'rgba(29, 135, 69, 0.04)',
            },
          }}
        >
          <Typography noWrap sx={{ color: '#242731' }}>
            {name}
          </Typography>

          <Typography noWrap sx={{ fontWeight: 600, color: '#242731' }}>
            {productsCount}
          </Typography>
        </ButtonBase>
      ))}
    </ItemsBox>
  );
};
