import { ChangeEvent, useEffect, useState } from 'react';
import { emailRegexp, ROLE_KEY } from 'shared/constants';
import { useAppSelector } from 'shared/hooks';
import { useParams } from 'react-router-dom';
import { CheckUserEmailDTO, CheckUserEmailResponse } from '../../../shared/models';
import { selectAllRoles, selectOpenInviteUser } from '../../../shared/slices';
import { UserService } from 'shared/services';
import { SelectChangeEvent } from '@mui/material';

const initialUserData = {
  name: '',
  email: '',
  roles: [] as string[],
  occupation: '',
  description: '',
};

export const useInviteUserValues = (onPhoneChange: (phone: string) => void) => {
  const { organizationId } = useParams();
  const openInviteUser = useAppSelector(selectOpenInviteUser);
  const allRoles = useAppSelector(selectAllRoles);

  const [isLoading, setIsLoading] = useState(false);
  const [isExist, setIsExist] = useState(true);
  const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false);

  const [isCanShowInfoText, setIsCanShowInfoText] = useState(false);

  const [userData, setUserData] = useState(initialUserData);

  const [nameErrorText, setNameErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [rolesErrorText, setRolesErrorText] = useState('');
  const [descriptionErrorText, setDescriptionErrorText] = useState('');

  const inviteError = nameErrorText || emailErrorText || rolesErrorText;

  const checkEmail = async (email?: string) => {
    setIsUserAlreadyAdded(false);
    setIsExist(false);

    const currentEmail = email || userData.email;

    if (!organizationId) return;
    if (!emailRegexp.test(currentEmail)) {
      return;
    }

    const body: CheckUserEmailDTO = { organizationId: +organizationId, email: currentEmail };
    setIsLoading(true);

    const response = await UserService.checkUserExist(body);
    if (response) {
      const { name, phone, userExistIn, userExistInCurrent }: CheckUserEmailResponse = response;

      if (userExistIn) {
        if (userExistInCurrent) {
          setIsUserAlreadyAdded(true);
        }
        setUserData((prev) => ({ ...prev, name, phone }));
        onPhoneChange && onPhoneChange(phone);
        setNameErrorText('');
        setIsCanShowInfoText(true);
        setIsExist(true);
      } else {
        setUserData((prev) => ({ ...prev, phone: '', name: '' }));
        setIsExist(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (openInviteUser) {
      setUserData(initialUserData);
      setNameErrorText('');
      setEmailErrorText('');
      setRolesErrorText('');
      setDescriptionErrorText('');
      setIsUserAlreadyAdded(false);
      setIsExist(false);
    }
  }, [openInviteUser]);

  const checkIsNameValid = () => {
    if (!userData.name) {
      setNameErrorText('Please enter user name');
    } else if (userData.name.length > 50) {
      setNameErrorText('Name length should be max 50');
    } else {
      setNameErrorText('');
    }
  };

  const checkIsEmailValid = (email?: string) => {
    const currentEmail = email || userData.email;
    if (!currentEmail) {
      setEmailErrorText('Invalid email');
    } else if (!emailRegexp.test(currentEmail)) {
      setEmailErrorText('Invalid email');
    } else {
      setEmailErrorText('');
    }
  };

  const checkIsDescriptionValid = () => {
    if (userData.description.length > 50) {
      setDescriptionErrorText('Description length should be max 50');
    } else {
      setDescriptionErrorText('');
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const onOccupationChange = (e: SelectChangeEvent<unknown>) => {
    setUserData((prev) => ({ ...prev, occupation: e.target.value as string }));
  };

  const onRolesChange = (roles: string[]) => {
    setUserData((prev) => ({ ...prev, roles }));
    setRolesErrorText('');
  };

  const checkIsValid = () => {
    setNameErrorText('');
    setEmailErrorText('');
    setRolesErrorText('');
    setDescriptionErrorText('');

    let isValid = true;

    if (!userData.name) {
      setNameErrorText('Please enter user name');
      isValid = false;
    } else if (userData.name.length > 50) {
      setNameErrorText('Name length should be max 50');
      isValid = false;
    }

    if (!userData.email) {
      setEmailErrorText('Please enter email');
      isValid = false;
    } else if (!emailRegexp.test(userData.email)) {
      setEmailErrorText('Invalid email');
      isValid = false;
    }

    if (!userData.roles.length) {
      setRolesErrorText('You must select permissions to invite user');
      isValid = false;
    }

    if (userData.description.length > 50) {
      setDescriptionErrorText('Description length should be max 50');
      isValid = false;
    }

    return isValid;
  };

  const rapidRole = allRoles.find((r) => r.key === ROLE_KEY.RAPID_USER);
  const cadDesignerRole = allRoles.find((r) => r.key === ROLE_KEY.CAD_DESIGNER);
  const isOnlyRapidRoleSelected =
    rapidRole && userData.roles.length === 1 && userData.roles.includes(rapidRole.id);

  const isOnlyCadDesignerRoleSelected =
    cadDesignerRole && userData.roles.length === 1 && userData.roles.includes(cadDesignerRole.id);

  return {
    userData,
    setUserData,
    onInputChange,
    onRolesChange,
    onOccupationChange,
    checkEmail,
    checkIsValid,
    checkIsEmailValid,
    checkIsNameValid,
    checkIsDescriptionValid,
    nameErrorText,
    emailErrorText,
    rolesErrorText,
    descriptionErrorText,
    inviteError,
    setIsCanShowInfoText,
    isCheckLoading: isLoading,
    isExist: isExist && isCanShowInfoText,
    isUserAlreadyAdded: isUserAlreadyAdded && isCanShowInfoText,
    isOnlyCadDesignerRoleSelected,
    isOnlyRapidRoleSelected,
  };
};
