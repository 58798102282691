import { Box, CircularProgress, InputAdornment, MenuItem, Select, SelectProps } from '@mui/material';
import { FC } from 'react';
import { LanguageData } from 'shared/models';
import { Icon } from 'shared/ui';

type Props = SelectProps & {
  language: string;
  languages: LanguageData[];
  isLanguagesLoading: boolean;
};

const LANGUAGES_ICONS: { [key: string]: string } = {
  'en-US': 'https://flagcdn.com/w40/us.png',
  'fr-FR': 'https://flagcdn.com/w40/fr.png',
  'he-IL': 'https://flagcdn.com/w40/il.png',
  'es-ES': 'https://flagcdn.com/w40/es.png',
  'pt-BR': 'https://flagcdn.com/w40/br.png',
  'pt-PO': 'https://flagcdn.com/w40/pt.png',
  'ot-OT': 'https://flagcdn.com/w40/aq.png',
};

export const LanguageSelect: FC<Props> = ({ language, languages, isLanguagesLoading, ...props }) => {
  return (
    <Select
      {...props}
      startAdornment={
        isLanguagesLoading ? (
          <InputAdornment position="start">
            <CircularProgress size={15} color="primary" />
          </InputAdornment>
        ) : (
          <Box component="span" sx={{ display: 'inline-flex', '& > img': { mr: 1.5, flexShrink: 0 } }}>
            <img
              style={{ height: '15px', width: '24px' }}
              src={LANGUAGES_ICONS[languages.find((i) => i.id === language)?.culture ?? 'ot-OT']}
              alt="country-flag"
            />
          </Box>
        )
      }
      IconComponent={(props) => (
        <Icon path="select-drop-icon" {...props} sx={{ mt: '4px', right: '0 !important' }} />
      )}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '24px',
        boxShadow: 'none',
        p: 0,
        '.MuiOutlinedInput-notchedOutline': { border: 'none' },
        '.MuiOutlinedInput-input': { display: 'flex', alignItems: 'center', p: 0, pr: '22px !important' },
        color: '#000',
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.16px',
      }}
    >
      {[...languages]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
    </Select>
  );
};
