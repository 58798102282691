import { FC, RefObject } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddItemButton, ItemsBox } from 'shared/ui';
import { NEW_ITEM_ID } from '../../hooks';
import { GroupItem } from 'entities/Group/components/GroupItem';
import { BoxHeader } from './GroupsSubtypesBrands';
import { Group, SubType } from 'shared/models';

type Props = {
  isViewer: boolean;
  selectedIDs: { subtype: string; group: string; brand: string };
  disabled: boolean;
  isGroupsDisabled: boolean;
  loadingIDs: string[];
  subtypeItems: SubType[];
  groupItems: Group[];
  onChangeSelectedIDs: (id: string) => void;
  onCreateGroup: (groupName: string) => Promise<void>;
  onChangeGroup: (
    id: string,
    name: string,
    legacyGroupId: string | null,
    subtypeId: string | null
  ) => Promise<void>;
  onDeleteGroup: (id: string) => Promise<void>;
  setActiveBox: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => void;
  selectedItemRef: RefObject<HTMLElement>;
};

export const GroupsTable: FC<Props> = ({
  isViewer,
  selectedIDs,
  loadingIDs,
  disabled,
  isGroupsDisabled,
  subtypeItems,
  groupItems,
  onCreateGroup,
  onChangeGroup,
  onDeleteGroup,
  setActiveBox,
  onChangeSelectedIDs,
  onKeyDown,
  selectedItemRef,
}) => {
  const { t } = useTranslation();

  return (
    <ItemsBox onClick={setActiveBox} sx={{ width: 940, minWidth: 940 }}>
      <Box sx={{ bgcolor: '#fff', position: 'sticky', top: 0, zIndex: 2 }}>
        <Stack direction="row">
          <BoxHeader noWrap sx={{ width: '15%', borderRight: '1px solid lightgrey' }}>
            {t('Group ID')}
          </BoxHeader>

          <BoxHeader noWrap sx={{ width: '34%', borderRight: '1px solid lightgrey' }}>
            {t('Group Name')}
          </BoxHeader>

          <BoxHeader noWrap sx={{ width: '25.5%', borderRight: '1px solid lightgrey' }}>
            {t('Subtype')}
          </BoxHeader>

          <BoxHeader noWrap sx={{ width: '25.5%' }}>
            {t('Brand')}
          </BoxHeader>
        </Stack>

        {!isViewer && (
          <AddItemButton
            insideTable
            isOpenInput={selectedIDs.group === NEW_ITEM_ID.group}
            onOpen={() => onChangeSelectedIDs(NEW_ITEM_ID.group)}
            onClose={() => onChangeSelectedIDs('')}
            onConfirm={onCreateGroup}
            disabled={disabled}
            title={'+ ' + t('Create Group')}
            errorText={'Group name must be min 3 characters and max 50'}
            isLoading={loadingIDs.includes(NEW_ITEM_ID.group)}
          />
        )}
      </Box>

      {groupItems.map((g) => (
        <Box ref={g.id === selectedIDs.group ? (selectedItemRef as RefObject<HTMLDivElement>) : null} key={g.id}>
          <GroupItem
            groupInfo={g}
            onDelete={() => onDeleteGroup(g.id)}
            onChangeGroup={onChangeGroup}
            subTypes={subtypeItems}
            isSelected={g.id === selectedIDs.group}
            onSelect={(id: string) => {
              onChangeSelectedIDs(id);
              setActiveBox();
            }}
            isLoading={loadingIDs.includes(g.id)}
            disabled={disabled || isGroupsDisabled}
            onKeyDown={onKeyDown}
          />
        </Box>
      ))}
    </ItemsBox>
  );
};
