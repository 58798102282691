import { Box, Collapse } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { OrgRow } from './OrgRow';
import { borderDashStyle } from './borderDashStyle';
import { OrgWithActiveUsersCount } from 'widgets';

interface Props {
  org: OrgWithActiveUsersCount;
  depth: number;
  childOrgs?: ReactNode;
  isLastChild?: boolean;
}

export const OrganizationItem: FC<Props> = ({ org, depth, childOrgs, isLastChild }) => {
  const orgsState = JSON.parse(localStorage.getItem('organizationState') ?? '[]');
  const isOrgOpen = orgsState.includes(org?.id);

  const [isOpen, setIsOpen] = React.useState(isOrgOpen);
  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Box sx={{ position: 'relative' }}>
      <OrgRow org={org} show={handleToggle} depth={depth} />

      <Collapse in={isOpen}>
        <div>{childOrgs}</div>
      </Collapse>

      <Box
        sx={{
          position: 'absolute',
          width: '1px',
          height: 'calc(100% - 60px)',
          top: '30px',
          left: `${depth * 28 + 20}px`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '2px',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 0,
            ...borderDashStyle,
          }}
        />

        {isLastChild && (
          <Box
            sx={{
              position: 'absolute',
              width: '10px',
              height: '100%',
              top: 0,
              left: `-33px`,
              zIndex: 5,
              overflow: 'hidden',
              bgcolor: 'white',
            }}
          />
        )}
      </Box>
    </Box>
  );
};
