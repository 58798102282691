import { FC } from 'react';
import { IconButton, Popover, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAnchorElement } from 'shared/hooks';
import { Icon } from 'shared/ui';

type Props = {
  error: string;
};

export const ErrorMessagePopover: FC<Props> = ({ error }) => {
  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  return (
    <>
      <IconButton onClick={onOpenMenu} sx={{ p: '0px' }}>
        <Icon path="error-help" sx={{ width: '14px' }} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ '& .MuiPaper-root': { borderRadius: '6px' }, mt: 1 }}
      >
        <Stack
          alignItems={'center'}
          sx={{ textAlign: 'center', maxWidth: '230px', color: 'error.main', fontSize: 14, p: 1, pt: 0 }}
        >
          <PlayArrowIcon sx={{ height: '16px', rotate: '90deg', color: 'error.main' }} />

          {error}
        </Stack>
      </Popover>
    </>
  );
};
