import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { createContext, useContext, useEffect } from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    lightsome: Palette['primary'];
  }

  interface PaletteOptions {
    lightsome: PaletteOptions['primary'];
  }

  interface Palette {
    darksome: Palette['primary'];
  }

  interface PaletteOptions {
    darksome: PaletteOptions['primary'];
  }
}

interface IThemeState {
  toggleColorMode: () => void;
}

export const ColorModeContext = createContext<IThemeState>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {},
});

export const useThemeProvider = () => {
  const { toggleColorMode } = useContext(ColorModeContext);

  return toggleColorMode;
};

export const MUIThemeProvider = (component: () => React.ReactNode) => () => {
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');

  const toggleColorMode = () => {
    // temporary disabled dark theme
    // const newValue = mode === 'light' ? 'dark' : 'light';
    const newValue = 'light';
    setMode(newValue);
    localStorage.setItem('themeMode', newValue);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode === 'dark' || savedMode === 'light') {
      setMode(savedMode);
    }
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'dark'
            ? {
                primary: {
                  main: 'rgba(29, 135, 66, 1)',
                  dark: 'rgba(19, 94, 45, 1)',
                  light: 'rgba(29, 135, 66, 0.32)',
                },
                info: {
                  main: 'rgba(255, 255, 255, 1)',
                  light: 'rgba(229, 229, 229, 1)',
                  dark: 'rgba(177, 177, 177, 1)',
                },
                darksome: {
                  main: 'rgba(0, 0, 0, 1)',
                  dark: 'rgba(29, 135, 66, 1)',
                  light: 'rgba(255, 255, 255, 0.5)',
                },
                lightsome: {
                  main: 'rgba(255, 255, 255, 1)',
                  light: 'rgba(255, 255, 255, 1)',
                  dark: 'rgba(255, 255, 255, 1)',
                },
                secondary: {
                  main: 'rgba(62, 62, 62, 1)', //rgba(29, 135, 66, 0.5)
                  dark: 'rgba(44, 44, 44, 1)', //rgba(255, 255, 255, 1)
                  light: 'rgba(255, 255, 255, 1)', //rgba(44, 44, 44, 1)
                  contrastText: 'rgba(44, 44, 44, 1)', //rgba(237, 248, 255, 1)
                },
                success: {
                  main: 'rgba(29, 135, 66, 0.3)', //rgba(62, 62, 62, 0.3)
                  light: 'rgba(29, 135, 66, 1)', //rgba(255, 255, 255, 0.5)
                  dark: 'rgba(255, 255, 255, 0.5)', //rgba(29, 135, 66, 1)
                  contrastText: 'rgba(255, 255, 255, 1)', //rgba(29, 135, 66, 1)
                },
                error: {
                  main: 'rgba(236, 97, 97, 1)',
                },
                warning: {
                  main: 'rgba(233, 192, 0, 1)',
                },
              }
            : {
                primary: {
                  main: 'rgba(29, 135, 66, 1)',
                  dark: 'rgba(19, 94, 45, 1)',
                  light: 'rgba(29, 135, 66, 0.3)',
                },
                info: {
                  main: 'rgba(255, 255, 255, 1)',
                  light: 'rgba(229, 229, 229, 1)',
                  dark: 'rgba(177, 177, 177, 1)',
                },
                darksome: {
                  main: 'rgba(0, 0, 0, 1)',
                  dark: 'rgba(0, 0, 0, 1)',
                  light: 'rgba(0, 0, 0, 1)',
                },
                lightsome: {
                  main: 'rgba(255, 255, 255, 1)',
                  light: 'rgba(29, 135, 66, 1)',
                  dark: 'rgba(177, 177, 177, 1)',
                },
                secondary: {
                  main: 'rgba(29, 135, 66, 0.5)',
                  dark: 'rgba(255, 255, 255, 1)',
                  light: 'rgba(44, 44, 44, 1)',
                  contrastText: 'rgba(237, 248, 255, 1)', //rgba(44, 44, 44, 1),
                },
                success: {
                  main: 'rgba(62, 62, 62, 0.3)',
                  light: 'rgba(255, 255, 255, 0.5)',
                  dark: 'rgba(29, 135, 66, 1)',
                  contrastText: 'rgba(29, 135, 66, 1)',
                },
                error: {
                  main: 'rgba(236, 97, 97, 1)',
                },
                warning: {
                  main: 'rgba(253, 221, 86, 1)',
                },
              }),
        },
        typography: {
          fontFamily: 'Myriad Pro',
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                width: '100svw',
                maxWidth: '100svw',
                height: '100svh',
                maxHeight: '100svh',
                overflow: 'hidden',
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={{ toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {component()}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
