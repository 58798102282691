import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector, useCatalogSearchParams, useCatalogsControl } from 'shared/hooks';
import { Group } from 'shared/models';
import { BrandService, GroupService, SubtypeService } from 'shared/services';
import {
  selectCatalogTypes,
  selectCurrentCatalog,
  selectGroups,
  setCurrentCatalogBrands,
  setCurrentCatalogSubtypes,
  setGroups,
  setIsSynchronizedCatalogData,
  setShouldUpdateCatalogProducts,
} from 'shared/slices';
import { CATALOG_TYPE } from 'shared/constants';

export enum NEW_ITEM_ID {
  group = 'new_group',
  subtype = 'new_subtype',
  brand = 'new_brand',
}

export const useManageGroups = () => {
  const dispatch = useAppDispatch();

  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const catalogTypes = useAppSelector(selectCatalogTypes);
  const groups = useAppSelector(selectGroups);

  const { items } = useCatalogsControl();

  const { catalogId, type, onChangeManageType } = useCatalogSearchParams();

  const [selectedIDs, setSelectedIDs] = useState({ subtype: '', group: '', brand: '' });
  const [loadingIDs, setLoadingIDs] = useState<string[]>([]);
  const [isGroupsTabDisabled, setIsGroupsTabDisabled] = useState(false);

  const [activeBox, setActiveBox] = useState<'' | 'type' | 'group' | 'subtype' | 'brand'>('');
  const selectedItemRef = useRef<HTMLElement>(null);

  const currentCatalogGroups = groups?.[catalogId] ?? [];
  const groupItems = currentCatalogGroups?.filter((g) => g.type === type) ?? [];
  const subtypeItems = currentCatalog?.subtypes?.filter((s) => s.type === type) ?? [];
  const brandItems = currentCatalog?.brands ?? [];

  const filteredGroups = useMemo(() => {
    if (!selectedIDs.subtype || selectedIDs.subtype === NEW_ITEM_ID.subtype) return groupItems;

    return groupItems.filter((gi) => gi.subtype?.id === selectedIDs.subtype);
  }, [selectedIDs.subtype, groupItems]);

  useEffect(() => {
    groupItems?.sort((g1, g2) => (g1.name < g2.name ? 1 : -1));
    subtypeItems?.sort((s1, s2) => (s1.name < s2.name ? 1 : -1));
    onChangeSelectedIDs('');
  }, [catalogId, type]);

  const onChangeSelectedIDs = (id: string, type: 'subtype' | 'group' | 'brand' | 'all' = 'all') => {
    if (type === 'all') {
      setSelectedIDs({ subtype: '', group: '', brand: '' });
      return;
    }

    const selectedID = selectedIDs[type];
    setSelectedIDs((prev) => ({ ...prev, [type]: id === selectedID ? '' : id }));
  };

  const onArrowKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>,
    boxType: '' | 'type' | 'group' | 'subtype' | 'brand'
  ) => {
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') return;
    e.preventDefault();

    const items = (() => {
      switch (boxType) {
        case 'brand':
          return brandItems.map((item) => item.id);
        case 'group':
          return groupItems.map((item) => item.id);
        case 'subtype':
          return subtypeItems.map((item) => item.id);
        case 'type':
          return catalogTypes.filter((item) => item !== CATALOG_TYPE.DISTRIBUTION_CURVE);
        default:
          return [];
      }
    })();

    if (!boxType) return;

    const isTypeBox = boxType === 'type';

    // find the index of current selected item, check by name for 'type' box and by ID for other boxes
    const currentItemIndex = items.findIndex((item) =>
      isTypeBox ? item === type : item === selectedIDs[boxType]
    );

    if (currentItemIndex === -1) return;

    if (e.key === 'ArrowDown' && currentItemIndex < items.length - 1) {
      const newItem = items[currentItemIndex + 1];

      isTypeBox ? onChangeManageType(newItem) : onChangeSelectedIDs(newItem, boxType);
    } else if (e.key === 'ArrowUp' && currentItemIndex > 0) {
      const newItem = items[currentItemIndex - 1];

      isTypeBox ? onChangeManageType(newItem) : onChangeSelectedIDs(newItem, boxType);
    }
  };

  const onKeyDownWithScroll = (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
    onArrowKeyPress(e, activeBox);

    if (selectedItemRef.current) {
      const target = selectedItemRef.current;
      const parent = target.parentNode as HTMLElement;

      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        if (e.key === 'ArrowDown') {
          parent.scrollTop -= parentRect.top - targetRect.top;
        }

        if (e.key === 'ArrowUp') {
          parent.scrollTop += targetRect.bottom - parentRect.bottom;
        }
      }
    }
  };

  const onChangeGroup = async (
    id: string,
    name: string,
    legacyGroupId: string | null,
    subtypeId: string | null
  ) => {
    const currentGroup = currentCatalogGroups.find((g) => g.id === id) as Group;

    name = name.trim();
    const isNewName = currentGroup.name !== name;

    const dto = {
      ...(isNewName && { name }),
      subtypeId,
      legacyGroupId: legacyGroupId || null,
    };

    if (isNewName) {
      if (checkIsInvalidName(name) || checkIsGroupExist(name)) return;
    }

    setIsGroupsTabDisabled(true);
    setLoadingIDs((prev) => [...prev, id]);

    const isUpdated = await GroupService.changeGroup(catalogId, id, dto);
    if (isUpdated) {
      const subtype = currentCatalog?.subtypes?.find((s) => s.id === subtypeId) ?? null;
      const groups = currentCatalogGroups.map((s) => (s.id === id ? { ...s, name, subtype, legacyGroupId } : s));
      dispatch(setGroups({ catalogId, groups }));

      dispatch(setShouldUpdateCatalogProducts(true));
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== id));
    setIsGroupsTabDisabled(false);
  };
  const onCreateGroup = async (groupName: string) => {
    const name = groupName.trim();

    if (checkIsInvalidName(name) || checkIsGroupExist(name)) return;

    setIsGroupsTabDisabled(true);
    setLoadingIDs((prev) => [...prev, NEW_ITEM_ID.group]);

    const newGroup = await GroupService.createGroup(catalogId, { name, type });
    if (newGroup) {
      const groups = [newGroup, ...currentCatalogGroups];
      dispatch(setGroups({ catalogId, groups }));

      onChangeSelectedIDs(newGroup.id, 'group');
    }

    setLoadingIDs((prev) => prev.filter((lId) => lId !== NEW_ITEM_ID.group));
    setIsGroupsTabDisabled(false);
  };
  const onDeleteGroup = async (id: string) => {
    setIsGroupsTabDisabled(true);
    setLoadingIDs((prev) => [...prev, id]);

    const isDeleted = await GroupService.deleteGroup(catalogId, id);
    if (isDeleted) {
      const currentGroupName = currentCatalogGroups.find((g) => g.id === id)?.name;

      if (currentGroupName) {
        const hasProducts = items.filter((c) => c.group === currentGroupName).length > 0;

        if (hasProducts) dispatch(setIsSynchronizedCatalogData(false));

        const groups = currentCatalogGroups.filter((g) => g.id !== id);
        dispatch(setGroups({ catalogId, groups }));

        dispatch(setShouldUpdateCatalogProducts(true));
      }
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== id));
    setIsGroupsTabDisabled(false);
  };

  const onChangeSubtype = async (
    id: string,
    updateData: { subtypeName: string; isLateral: boolean; isSubMain: boolean; isMainPipe: boolean }
  ) => {
    setLoadingIDs((prev) => [...prev, id]);

    const isUpdated = await SubtypeService.changeSubtype(catalogId, id, updateData);
    if (isUpdated) {
      const { subtypeName: name, ...includedInOptions } = updateData;
      const subtypes = currentCatalog?.subtypes.map((s) =>
        s.id === id ? { ...s, ...includedInOptions, name } : s
      );
      dispatch(setCurrentCatalogSubtypes(subtypes));

      dispatch(setShouldUpdateCatalogProducts(true));
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== id));
  };
  const onDeleteSubtype = async (id: string) => {
    setLoadingIDs((prev) => [...prev, id]);

    const isDeleted = await SubtypeService.deleteSubtype(catalogId, id);
    if (isDeleted) {
      const subtypes = currentCatalog?.subtypes.filter((s) => s.id !== id);
      const groups = currentCatalogGroups.map((g) => (g.subtype?.id === id ? { ...g, subtype: null } : g));
      dispatch(setCurrentCatalogSubtypes(subtypes));
      dispatch(setGroups({ catalogId, groups }));

      dispatch(setShouldUpdateCatalogProducts(true));
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== id));
  };
  const onCreateSubtype = async (subtypeName: string) => {
    const name = subtypeName.trim();

    if (checkIsInvalidName(name) || checkIsSubtypeExist(name)) return;

    setLoadingIDs((prev) => [...prev, NEW_ITEM_ID.subtype]);

    const newSubtype = await SubtypeService.createSubtype(catalogId, { subtypeName: name, type: type });
    if (newSubtype) {
      const subtypes = [newSubtype, ...currentCatalog.subtypes];
      dispatch(setCurrentCatalogSubtypes(subtypes));

      onChangeSelectedIDs(newSubtype.id, 'subtype');
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== NEW_ITEM_ID.subtype));
  };

  const onChangeBrand = async (id: string, name: string) => {
    name = name.trim();

    if (checkIsInvalidName(name) || checkIsBrandExist(name)) return;

    setLoadingIDs((prev) => [...prev, id]);

    const isUpdated = await BrandService.changeBrand(catalogId, id, name);
    if (isUpdated) {
      const brands = brandItems.map((b) => (b.id === id ? { ...b, name } : b));
      dispatch(setCurrentCatalogBrands(brands));

      dispatch(setShouldUpdateCatalogProducts(true));
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== id));
  };
  const onDeleteBrand = async (id: string) => {
    setLoadingIDs((prev) => [...prev, id]);

    const isDeleted = await BrandService.deleteBrand(catalogId, id);
    if (isDeleted) {
      const brands = brandItems.filter((b) => b.id !== id);
      dispatch(setCurrentCatalogBrands(brands));

      dispatch(setShouldUpdateCatalogProducts(true));
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== id));
  };
  const onCreateBrand = async (brandName: string) => {
    const name = brandName.trim();

    if (checkIsInvalidName(name) || checkIsBrandExist(name)) return;

    setLoadingIDs((prev) => [...prev, NEW_ITEM_ID.brand]);

    const newBrand = await BrandService.createBrand(catalogId, name);
    if (newBrand) {
      const brands = [newBrand, ...brandItems];
      dispatch(setCurrentCatalogBrands(brands));

      onChangeSelectedIDs(newBrand.id, 'brand');
    }
    setLoadingIDs((prev) => prev.filter((lId) => lId !== NEW_ITEM_ID.brand));
  };

  const checkIsGroupExist = (name: string) => {
    const groupNames = currentCatalogGroups.map((g) => g.name) ?? [];
    if (groupNames.includes(name)) {
      toast.error('Such group name already exists in the catalog.');
      return true;
    }
    return false;
  };
  const checkIsSubtypeExist = (name: string) => {
    const subtypeNames = currentCatalog.subtypes.map((s) => s.name) ?? [];
    if (subtypeNames.includes(name)) {
      toast.error('Such subtype name already exists in the catalog.');
      return true;
    }
    return false;
  };
  const checkIsBrandExist = (name: string) => {
    const brandNames = currentCatalog?.brands?.map((s) => s.name) ?? [];
    if (brandNames?.includes(name)) {
      toast.error('Such brand name already exists in the catalog.');
      return true;
    }
    return false;
  };
  const checkIsInvalidName = (name: string) => {
    return !name || name.length < 3 || name.length > 50;
  };

  return {
    type,
    onChangeManageType,
    groupItems,
    subtypeItems,
    brandItems,
    loadingIDs,
    filteredGroups,
    onCreateGroup,
    onDeleteGroup,
    onChangeGroup,
    onDeleteSubtype,
    onCreateSubtype,
    onChangeSubtype,
    onCreateBrand,
    onDeleteBrand,
    onChangeBrand,
    selectedIDs,
    onChangeSelectedIDs,
    isGroupsDisabled: isGroupsTabDisabled,
    selectedItemRef,
    setActiveBox,
    onKeyDownWithScroll,
  };
};
