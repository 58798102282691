import { FC, KeyboardEvent } from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogModal, ErrorTooltip, Icon, SaveControl } from 'shared/ui';
import { SubType } from 'shared/models';
import { useSubTypeItemActions } from './hooks';

interface Props {
  subTypeInfo: SubType;
  onDelete: () => void;
  onChangeSubtype: (
    id: string,
    updateData: {
      subtypeName: string;
      isLateral: boolean;
      isSubMain: boolean;
      isMainPipe: boolean;
    }
  ) => void;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isLoading?: boolean;
  disabled: boolean;
  isPipeType: boolean;
}

export const SubTypeItem: FC<Props> = ({
  subTypeInfo,
  onDelete,
  onChangeSubtype,
  isSelected,
  onSelect,
  onKeyDown,
  isLoading,
  disabled,
  isPipeType,
}) => {
  const { name } = subTypeInfo;

  const { t } = useTranslation();

  const {
    isConfirm,
    onToggleConfirm,
    nameValue,
    onChangeValue,
    isEdit,
    checkboxOptions,
    onOpenEdit,
    onCancelEdit,
    handleDelete,
    handleSelect,
    handleSaveNameEditing,
    toggleIncludedInOptions,
  } = useSubTypeItemActions({ subTypeInfo, disabled, onSelect, onDelete, onChangeSubtype });

  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          height: 38,
          borderBottom: '1px solid lightgrey',
          color: disabled ? 'info.dark' : 'black',
          cursor: 'pointer',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          onClick={handleSelect}
          onKeyDown={onKeyDown}
          tabIndex={0}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            pl: 1.5,
            pr: 2.5,
            width: isPipeType ? '45%' : '100%',
            height: '100%',
            bgcolor: isSelected ? 'rgba(29, 135, 69, 0.08) !important' : '#fff',
            color: disabled ? 'info.dark' : 'black',
            outline: 'none',
            ...(isPipeType && {
              borderRight: '1px solid lightgrey',
            }),
            '&:focus': { outline: 'none' },
            '&:hover': {
              bgcolor: 'rgba(29, 135, 69, 0.04)',
              '& .icons': {
                display: isLoading ? 'none' : 'flex',
              },
            },
          }}
        >
          {isEdit ? (
            <>
              <ErrorTooltip
                open={nameValue.length < 3 || nameValue.length > 50}
                title={'Group name must be between 3 and 50 characters.'}
                arrow
              >
                <InputBase
                  value={nameValue}
                  onChange={onChangeValue}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.code === 'Enter') {
                      handleSaveNameEditing();
                    }
                  }}
                  autoFocus
                  sx={{ input: { color: 'primary.main' } }}
                />
              </ErrorTooltip>

              <Stack direction="row">
                <IconButton onClick={onCancelEdit} sx={{ p: 0 }}>
                  <Icon path="circle-btn-close" />
                </IconButton>

                <IconButton onClick={handleSaveNameEditing} sx={{ p: 0 }}>
                  <Icon path="circle-btn-done" />
                </IconButton>
              </Stack>
            </>
          ) : (
            <>
              <Typography noWrap sx={{ width: '100%' }}>
                {name}
              </Typography>

              {!isSelected && isLoading && <CircularProgress size={15} color="primary" />}

              <Stack className="icons" direction="row" spacing={1} sx={{ display: isSelected ? 'flex' : 'none' }}>
                <IconButton onClick={onOpenEdit} sx={{ p: 0 }}>
                  <Icon path="group-edit" />
                </IconButton>

                <IconButton onClick={onToggleConfirm} sx={{ p: 0 }}>
                  <Icon path="group-delete" />
                </IconButton>
              </Stack>
            </>
          )}
        </Stack>

        {isPipeType && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={0.5}
            sx={{ width: '55%', height: '100%' }}
          >
            {checkboxOptions.map(({ name, value: checked, label }) => (
              <FormControlLabel
                key={name}
                checked={checked}
                onChange={() => toggleIncludedInOptions(name, checked ?? false)}
                label={
                  <Typography noWrap sx={{ fontSize: 14, color: checked ? 'primary.main' : '#727272' }}>
                    {label}
                  </Typography>
                }
                disabled={isLoading}
                control={
                  <Checkbox
                    icon={<Icon path="checkbox-green-icon" />}
                    checkedIcon={<Icon path="checkbox-green-icon-checked" />}
                    sx={{ mr: 1, width: 16, height: 16 }}
                  />
                }
                sx={{ px: 0.5 }}
              />
            ))}
          </Stack>
        )}
      </Stack>

      <DialogModal open={isConfirm} onClose={onToggleConfirm} maxWidth="xs">
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
          By deleting the sub type it will be removed from all linked groups
        </Typography>

        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>Are you sure ?</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl onSave={handleDelete} onCancel={onToggleConfirm} btnTexts={[t('yes'), t('no')]} />
        </Stack>
      </DialogModal>
    </>
  );
};
