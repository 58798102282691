import { ButtonBase, Select, SelectProps, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { Icon } from 'shared/ui';

type Props = SelectProps & {
  label: string;
  allowEmpty?: boolean;
  clearFilter?: () => void;
};

export const FilterSelect: FC<Props> = ({ label, value, allowEmpty, clearFilter, children, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isSelectedOption = Boolean(value);
  const isClearButtonShown = isSelectedOption && allowEmpty;

  const ArrowIcon = () => {
    const iconPath = `products-view/select-arrow-${isSelectedOption ? 'green' : 'black'}`;
    return (
      <Icon
        path={iconPath}
        sx={{
          position: 'absolute',
          right: isClearButtonShown ? 8 : 20,
          pointerEvents: 'none',
          ...(isMenuOpen && { transform: 'rotate(180deg)' }),
        }}
      />
    );
  };

  return (
    <Stack direction="row" alignItems="stretch" spacing={0.5}>
      <Select
        {...props}
        value={value}
        IconComponent={ArrowIcon}
        displayEmpty
        renderValue={(value) => {
          return value ? `${label}: ${value}` : label;
        }}
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        sx={{
          borderRadius: '16px',
          border: '1px solid #D4D4D4',
          background: '#F2F4F7',
          color: '#344054',
          fontWeight: 600,
          '& .MuiSelect-select': {
            p: 0,
            py: '10px',
            pl: '20px',
            pr: `${isClearButtonShown ? '34' : '46'}px !important`,
          },
          fieldset: { display: 'none' },
          ...(isSelectedOption && {
            borderColor: 'rgba(29, 135, 69, 0.16)',
            background: '#ECFDF3',
            color: '#027A48',
          }),
          ...(isClearButtonShown && {
            borderRadius: '16px 0px 0px 16px',
          }),
        }}
      >
        {children}
      </Select>

      {isClearButtonShown && (
        <ButtonBase
          onClick={clearFilter}
          sx={{
            px: '18px',
            borderRadius: '0px 16px 16px 0px',
            border: '1px solid rgba(29, 135, 69, 0.16)',
            background: '#ECFDF3',
          }}
        >
          <Icon path="products-view/select-close" />
        </ButtonBase>
      )}
    </Stack>
  );
};
