export const scrollStyles = {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'info.dark',
    borderRadius: '30px',
  },
  '&::-webkit-scrollbar': {
    height: '6px',
    width: '6px',
    backgroundColor: 'info.light',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'info.light',
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'info.light',
    background: 'info.light',
  },
};
