import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CATALOG_MANAGE_TAB, CATALOG_OWNER_TYPE } from 'shared/constants';
import { useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { Icon, TabPanel } from 'shared/ui';
import { CatalogAvailability, CatalogTranslations, GroupsSubtypesBrands } from './components';
import { selectCurrentCatalog } from 'shared/slices';
import { useMemo } from 'react';

export const CatalogManager = () => {
  const { t } = useTranslation();
  const { manageIndex, onChangeManageIndex } = useCatalogSearchParams();

  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const isAvailabilityTabIncluded = currentCatalog.owner?.type !== CATALOG_OWNER_TYPE.PRIVATE;

  const manageTabs = useMemo(() => {
    const availabilityTabs = isAvailabilityTabIncluded
      ? [
          {
            label: 'availability',
            iconPath: 'availability',
            value: CATALOG_MANAGE_TAB.AVAILABILITY,
            component: <CatalogAvailability />,
          },
        ]
      : [];

    return [
      {
        label: 'groups_subtypes',
        iconPath: 'group',
        value: CATALOG_MANAGE_TAB.GROUPS,
        component: <GroupsSubtypesBrands />,
      },
      {
        label: 'translations',
        iconPath: 'translations',
        value: CATALOG_MANAGE_TAB.TRANSLATIONS,
        component: <CatalogTranslations />,
      },
      ...availabilityTabs,
    ];
  }, [isAvailabilityTabIncluded]);

  const handleChangeTab = (_: React.SyntheticEvent, tabIndex: number) => onChangeManageIndex(tabIndex);

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <Box>
        <Tabs
          value={Number(manageIndex)}
          onChange={handleChangeTab}
          orientation="vertical"
          TabIndicatorProps={{
            sx: {
              display: 'none',
            },
          }}
          sx={{
            p: 2.5,
            height: '100%',
            borderRight: '1px solid #DDE0E6',
            '& .MuiTabs-flexContainer': { gap: 1.5 },
          }}
        >
          {manageTabs.map(({ label, value, iconPath }) => (
            <Tab
              key={value}
              label={
                <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                  <Icon path={iconPath} sx={{ width: 24 }} />
                  <Typography sx={{ lineHeight: '130%', textWrap: 'nowrap' }}>{t(label)}</Typography>
                </Stack>
              }
              sx={{
                alignItems: 'flex-start',
                p: 1.5,
                width: 'fit-content',
                borderRadius: '12px',
                border: '1px solid transparent',
                color: '#000',
                lineHeight: '130%',
                textTransform: 'none',
                '&.Mui-selected': {
                  color: '#000',
                  borderColor: '#D4D4D4',
                  bgcolor: '#FFF',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {manageTabs.map(({ value, component }) => (
        <TabPanel key={value} value={+manageIndex} index={value}>
          <Box sx={{ px: 2.5, height: '100%', bgcolor: '#FFF' }}>{component}</Box>
        </TabPanel>
      ))}
    </Stack>
  );
};
