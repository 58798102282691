import { FC } from 'react';
import { Box, CircularProgress, MenuItem as MuiMenuItem, Stack, Typography, styled } from '@mui/material';
import { Icon, Select } from 'shared/ui';
import { SubType } from 'shared/models';
import { useTranslation } from 'react-i18next';

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  backgroundColor: 'white',

  '&:hover': {
    backgroundColor: 'rgba(29, 135, 66, 0.1) !important',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
  },
}));

type Props = {
  subtype: SubType | null | undefined;
  disabled?: boolean;
  isSelected?: boolean;
  subTypes: SubType[];
  isSubtypeLoading: boolean;
  handleSaveSubType: (subtypeId: string | null) => void;
};

export const SubtypeCell: FC<Props> = ({
  subtype,
  disabled,
  isSelected,
  subTypes,
  isSubtypeLoading,
  handleSaveSubType,
}) => {
  const { t } = useTranslation();

  const subTypesIds = subTypes.map((st) => st.id);
  const subTypeIdValue = subtype?.id && subTypesIds.includes(subtype.id) ? subtype.id : '';

  return (
    <Stack
      justifyContent="center"
      sx={{
        position: 'relative',
        px: 1.5,
        width: '25.5%',
        height: '100%',
        borderRight: '1px solid lightgrey',
      }}
    >
      <Select
        value={subTypeIdValue || 'Select sub type'}
        disabled={disabled}
        onWhiteOnly
        isLoading={true}
        sx={{
          p: 0,
          width: '100%',
          border: 'none',
          fontSize: 16,
          '*': {
            WebkitTextFillColor: subtype?.id ? '#000' : isSelected ? 'white' : 'rgba(189, 189, 189, 1)',
          },
        }}
        IconComponent={(props) => (
          <Icon path="select-drop-icon" {...props} sx={{ mt: '4px', right: '0 !important' }} />
        )}
      >
        <MenuItem value={'Select sub type'} sx={{ display: 'none' }}>
          {t('Select sub type')}
        </MenuItem>

        <MenuItem value={''} onClick={() => handleSaveSubType(null)}>
          {t('None')}
        </MenuItem>

        {subTypes?.map(({ id: subtypeId, name }) => (
          <MenuItem value={subtypeId} key={subtypeId} onClick={() => handleSaveSubType(subtypeId)}>
            <Typography noWrap>{name}</Typography>
          </MenuItem>
        ))}
      </Select>

      {isSubtypeLoading && (
        <Box
          sx={{
            position: 'absolute',
            right: 5,
            height: '20px',
            width: '20px',
            bgcolor: 'white',
          }}
        >
          <CircularProgress size={15} color="primary" />
        </Box>
      )}
    </Stack>
  );
};
