import { Box, Stack } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { BigTitle, Loader, SearchInput } from 'shared/ui';
import { OrganizationsTable } from './components';
import { NewOrganizationModal } from 'features';
import { useTranslation } from 'react-i18next';
import { selectHomeTabState, selectOrganizations, selectOrgLoading, selectPermissions, setOrganizations } from '../../shared/slices';
import { Organization } from 'shared/models';
import { UserService } from 'shared/services';
import { STATUS } from 'shared/constants';

export interface OrgWithActiveUsersCount extends Organization {
  activeUsersCount?: number;
  subOrganizations: OrgWithActiveUsersCount[];
}

export const OrganizationsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const orgs = useAppSelector(selectOrganizations);
  const areOrgsLoading = useAppSelector(selectOrgLoading);
  const permissions = useAppSelector(selectPermissions);
  const { orgSearch } = useAppSelector(selectHomeTabState);

  const isListOrganizationPermissionAble = Object.values(permissions).some(
    (p) => p.organization && p.organization.list
  );

  const [searchValue, setSearchValue] = useState<string>(orgSearch);

  const [areOrgsUsersDataLoading, setAreOrgsUsersDataLoading] = useState(false);

  const onClearClick = () => setSearchValue('');
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  useEffect(() => {
    if (!isListOrganizationPermissionAble) return;

    const fetchOrgsUsersData = async () => {
      setAreOrgsUsersDataLoading(true);
      try {
        const updatedOrgs = await getOrgsWithActiveUsersCount(orgs);
        dispatch(setOrganizations(updatedOrgs));
      } catch (error) {
        console.log('Error fetching users for all organizations:', error);
      } finally {
        setAreOrgsUsersDataLoading(false);
      }
    };

    fetchOrgsUsersData();
  }, [isListOrganizationPermissionAble]);

  const getOrgsWithActiveUsersCount = async (orgs: Organization[]): Promise<OrgWithActiveUsersCount[]> => {
    const updatedOrgs = await Promise.all(
      orgs.map(async (organization) => {
        const users = await UserService.getOrgUsers(organization.id.toString());

        const activeUsersCount =
          users?.filter(({ status, roles }) => status === STATUS.ACTIVE && roles.length).length ?? 0;

        const updatedSubOrgs = await getOrgsWithActiveUsersCount(organization.subOrganizations);
        return {
          ...organization,
          activeUsersCount,
          subOrganizations: updatedSubOrgs,
        };
      })
    );

    return updatedOrgs;
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(setHomeTabState({ orgSearch: searchValue }));
  //   };
  // }, [searchValue]);

  return (
    <>
      <Box sx={{ height: '100%', p: '20px 40px' }}>
        <BigTitle sx={{ fontSize: 24 }}>{t('organizations')}</BigTitle>

        {areOrgsLoading || areOrgsUsersDataLoading ? (
          <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : (
          <Stack
            sx={{
              bgcolor: 'info.main',
              borderRadius: '8px',
              mt: '20px',
            }}
          >
            <Stack
              direction="row"
              spacing="25px"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ width: '100%', mt: '15px', px: '10px', borderBottom: '1px solid grey' }}
            >
              <Box sx={{ maxWidth: '354px', flexGrow: 1 }}>
                <SearchInput
                  value={searchValue}
                  autoComplete={'new-password'}
                  onChange={handleChangeSearchValue}
                  onClearClick={onClearClick}
                  name={t('search_organization')}
                  icon={'green-search'}
                  sx={{ '& .MuiInputBase-root': { bgcolor: 'none' } }}
                />
              </Box>
            </Stack>

            {isListOrganizationPermissionAble && (
              <OrganizationsTable orgs={orgs} searchValue={searchValue} />
            )}
          </Stack>
        )}
      </Box>

      <NewOrganizationModal />
    </>
  );
};
