import { FC } from 'react';
import { IconButton, Stack } from '@mui/material';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';

interface Props {
  onScrollToLeft?: () => void;
  onScrollToRight?: () => void;
}

export const TableArrowScrolling: FC<Props> = ({ onScrollToLeft, onScrollToRight }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems={'center'}
      spacing={0.5}
      sx={{ mt: '30px', mx: 'auto' }}
    >
      <IconButton onClick={onScrollToLeft} sx={{ p: 0 }}>
        <ArrowBackIosRounded sx={{ width: '15px', color: '#000' }} />
      </IconButton>
      <IconButton onClick={onScrollToRight} sx={{ p: 0 }}>
        <ArrowForwardIosRounded sx={{ width: '15px', color: '#000' }} />
      </IconButton>
    </Stack>
  );
};
