import { ChangeEvent, useState } from 'react';
import { axiosBase } from 'shared/axios';
import { TableProductInfo } from 'shared/models';
import { useAppDispatch, useCatalogSearchParams } from 'shared/hooks';
import { setShouldUpdateCatalogProducts } from 'shared/slices';
import { validateTranslationValue } from 'shared/lib';

type Args = {
  productItem: TableProductInfo;
  products: TableProductInfo[];
  selectedLanguageId: string;
  updateProduct: (updatedComponent: TableProductInfo) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export const useProductTranslations = ({
  productItem,
  products,
  selectedLanguageId,
  updateProduct,
  onKeyDown,
}: Args) => {
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();

  const [localProductItem, setLocalProductItem] = useState<TableProductInfo>(productItem);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [translationError, setTranslationError] = useState<string | null>(null);

  const productDescriptions = products
    .filter((product) => product.id !== productItem.id)
    .map((product) => product.description || '');

  const productTranslations = products
    .filter((product) => product.id !== productItem.id)
    .map(
      (product) =>
        product.stringResources?.find((translation) => translation.languageId === selectedLanguageId)?.value || ''
    )
    .filter((translation) => translation);

  const onDescriptionChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newDesc = e.target.value;

    setLocalProductItem((prev) => ({ ...prev, description: newDesc }));

    const validatedDescr = validateTranslationValue(newDesc, productDescriptions, 'Description');
    setDescriptionError(validatedDescr);
  };

  const onTranslationChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newTranslation = e.target.value;

    const stringResources = localProductItem.stringResources;

    const newTranslationData = {
      languageId: selectedLanguageId,
      value: newTranslation,
    };

    const validatedTranslation = validateTranslationValue(newTranslation, productTranslations, 'Translation');
    setTranslationError(validatedTranslation);

    if (stringResources) {
      const filteredStringResources = stringResources.filter((sr) => sr.languageId !== selectedLanguageId);

      const newStringResources = [...filteredStringResources, newTranslationData];

      setLocalProductItem((prev) => ({
        ...prev,
        stringResources: newStringResources,
      }));

      return;
    }

    setLocalProductItem((prev) => ({
      ...prev,
      stringResources: [newTranslationData],
    }));
  };

  const getDescriptionChanges = () => localProductItem.description !== productItem.description;

  const getTranslationChanges = () => {
    const localItemTranslation = localProductItem.stringResources?.find((sr) => sr.languageId === selectedLanguageId)
      ?.value;
    const itemTranslation = productItem.stringResources?.find((sr) => sr.languageId === selectedLanguageId)?.value;
    return localItemTranslation !== itemTranslation;
  };

  const onBlur = async () => {
    if (descriptionError || translationError) return;

    const isDescriptionChanged = getDescriptionChanges();
    const isTranslationChanged = getTranslationChanges();

    if (isDescriptionChanged || isTranslationChanged) {
      try {
        const body = [
          {
            itemRef: 0,
            operation: 'Update',
            groupId: localProductItem.groupId,
            componentId: localProductItem.id,
            componentData: {
              ...productItem,
              description: localProductItem.description,
              descriptionStringResources: localProductItem.stringResources,
            },
          },
        ];

        await axiosBase.put(`/Catalogs/${catalogId}/components`, body);

        dispatch(setShouldUpdateCatalogProducts(true));
        updateProduct(localProductItem);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onBlur();
    } else {
      onKeyDown(e);
    }
  };

  return {
    localProductItem,
    descriptionError,
    translationError,
    onDescriptionChange,
    onTranslationChange,
    onBlur,
    handleKeyDown,
  };
};
