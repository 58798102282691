import { FC } from 'react';
import { IconButton, Stack, Typography, styled } from '@mui/material';
import { CATALOG_OWNER_TYPE } from 'shared/constants';
import { useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { ActiveButton, BlackToolTip, Icon } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { selectTabs } from 'shared/slices';

const Text = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '0.18px',
  color: '#000',
  cursor: 'pointer',
});

const catalogTypeIcons = {
  [CATALOG_OWNER_TYPE.ORG]: 'products-view/catalog-type-org',
  [CATALOG_OWNER_TYPE.PRIVATE]: 'products-view/catalog-type-private',
  [CATALOG_OWNER_TYPE.PUBLISHED]: 'products-view/catalog-type-public',
};
const catalogTypeTitles = {
  [CATALOG_OWNER_TYPE.ORG]: 'Organization',
  [CATALOG_OWNER_TYPE.PRIVATE]: 'Private',
  [CATALOG_OWNER_TYPE.PUBLISHED]: 'Public',
};

interface Props {
  isEditing?: boolean;
}

export const CatalogInfo: FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation();
  const { catalogId, manageIndex, navigateToCatalogManagement } = useCatalogSearchParams();

  const tabs = useAppSelector(selectTabs);

  const isManagementPage = Boolean(manageIndex?.toString());
  const catalogTab = tabs?.find((c) => c.id === catalogId);
  const catalogName = catalogTab?.title;

  const catalogTypeIcon = catalogTypeIcons[catalogTab?.catalogInfo.type ?? CATALOG_OWNER_TYPE.PUBLISHED];
  const catalogTypeTitle = catalogTypeTitles[catalogTab?.catalogInfo.type ?? CATALOG_OWNER_TYPE.PUBLISHED];
  const catalogOwner = catalogTab?.catalogInfo.owner ?? 'Unknown';

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ px: 3, py: 2, borderBottom: '0.5px solid #D4D4D4' }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
          <Icon path="products-view/catalog-title-icon" />
          <BlackToolTip title={t('Catalog Name')}>
            <Text sx={{ color: '#1D8742' }}>{catalogName}</Text>
          </BlackToolTip>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing="6px">
          <Icon path={catalogTypeIcon} />
          <BlackToolTip title={t('Catalog Type')}>
            <Text>{catalogTypeTitle}</Text>
          </BlackToolTip>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing="6px">
          <Icon path="products-view/catalog-owner" />
          <BlackToolTip title={t('Catalog Owner')}>
            <Text>{catalogOwner}</Text>
          </BlackToolTip>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between"></Stack>

      <Stack direction="row" alignItems="center" spacing={3}>
        <BlackToolTip title={t('Settings')}>
          <ActiveButton
            onClick={navigateToCatalogManagement}
            isActive={isManagementPage}
            defaultBgcolor="secondary.contrastText"
          >
            <Icon path={`products-view/catalog-settings-${isManagementPage ? 'green' : 'black'}`} />
          </ActiveButton>
        </BlackToolTip>

        <BlackToolTip title={t('Import')}>
          <IconButton sx={{ p: 0, borderRadius: '6px' }}>
            <Icon path="products-view/catalog-export" />
          </IconButton>
        </BlackToolTip>
      </Stack>
    </Stack>
  );
};
