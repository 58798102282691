import { MenuItem, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { separateByUpperCase } from 'shared/lib';
import { Group, SubType } from 'shared/models';
import { FilterSelect } from 'shared/ui';

type Props = {
  type: string;
  catalogTypes: string[];
  onTypeChange: (type: string) => void;
  subtypeFilterValue?: string;
  subtypeItems: SubType[];
  onChangeSubtype: (subtypeId: string) => void;
  groupFilterValue?: string;
  groupItems: Group[];
  onChangeGroup: (groupId: string) => void;
};

export const Filters: FC<Props> = ({
  type,
  catalogTypes,
  onTypeChange,
  subtypeFilterValue,
  subtypeItems,
  onChangeSubtype,
  groupFilterValue,
  groupItems,
  onChangeGroup,
}) => {
  const { t } = useTranslation();

  const isNoTypes = !catalogTypes.length;

  return (
    <Stack direction="row" spacing={3} sx={{ py: 3 }}>
      <FilterSelect label={t('type')} value={isNoTypes ? '' : type}>
        {catalogTypes.map((type) => (
          <MenuItem key={type} value={type} onClick={() => onTypeChange(type)}>
            {separateByUpperCase(type)}
          </MenuItem>
        ))}
      </FilterSelect>

      <FilterSelect
        label={t('subtype')}
        value={subtypeFilterValue ?? ''}
        clearFilter={() => onChangeSubtype('')}
        allowEmpty
      >
        {subtypeItems.map(({ id, name }) => (
          <MenuItem key={id} value={name} onClick={() => onChangeSubtype(id)}>
            {name}
          </MenuItem>
        ))}
      </FilterSelect>

      <FilterSelect
        label={t('group')}
        value={groupFilterValue ?? ''}
        clearFilter={() => onChangeGroup('')}
        allowEmpty
      >
        {groupItems.map(({ id, name }) => (
          <MenuItem key={id} value={name} onClick={() => onChangeGroup(id)}>
            {name}
          </MenuItem>
        ))}
      </FilterSelect>
    </Stack>
  );
};
