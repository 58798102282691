import { ChangeEvent, FC, KeyboardEvent } from 'react';
import { CircularProgress, IconButton, InputBase, Stack, Typography } from '@mui/material';
import { ErrorTooltip, Icon } from 'shared/ui';

type Props = {
  idValue: string;
  isIdEditing: boolean;
  idError: boolean;
  legacyGroupId?: string | null;
  isLegacyIdLoading: boolean;
  isSelected?: boolean;
  onChangeIdValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSaveLegacyId: () => void;
  onCancelEditing: () => void;
  onOpenIdEditing: () => void;
  handleSelect: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export const GroupIdCell: FC<Props> = ({
  idValue,
  isIdEditing,
  idError,
  legacyGroupId,
  isLegacyIdLoading,
  isSelected,
  onChangeIdValue,
  handleSaveLegacyId,
  onCancelEditing,
  onOpenIdEditing,
  handleSelect,
  onKeyDown,
}) => {
  return (
    <Stack
      onClick={handleSelect}
      onKeyDown={onKeyDown}
      tabIndex={0}
      direction="row"
      alignItems="center"
      sx={{
        py: 1,
        pl: 1.5,
        pr: 2.5,
        width: '15%',
        height: '100%',
        borderRight: '1px solid lightgrey',
        outline: 'none',
        '&:focus': { outline: 'none' },
        '&:hover': {
          '& .icons': {
            display: isLegacyIdLoading ? 'none' : 'flex',
          },
        },
      }}
    >
      {isIdEditing ? (
        <>
          <ErrorTooltip open={idError} title={'Max 7 characters.'} arrow>
            <InputBase
              value={idValue}
              onChange={onChangeIdValue}
              autoFocus
              type="number"
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter') {
                  handleSaveLegacyId();
                }
              }}
              sx={{
                p: 0,
                input: { p: 0, color: 'primary.main' },
                'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
              }}
            />
          </ErrorTooltip>

          <Stack direction="row" sx={{ pl: '7px', mr: -0.5 }}>
            <IconButton onClick={onCancelEditing} sx={{ p: 0 }}>
              <Icon path="circle-btn-close" />
            </IconButton>

            <IconButton onClick={handleSaveLegacyId} sx={{ p: 0 }}>
              <Icon path="circle-btn-done" />
            </IconButton>
          </Stack>
        </>
      ) : (
        <>
          <Typography noWrap sx={{ width: '100%' }}>
            {legacyGroupId}
          </Typography>

          {isLegacyIdLoading && <CircularProgress size={15} color="primary" />}

          <Stack className="icons" direction="row" sx={{ display: isSelected ? 'flex' : 'none' }}>
            <IconButton onClick={onOpenIdEditing} sx={{ p: 0 }}>
              <Icon path="group-edit" />
            </IconButton>
          </Stack>
        </>
      )}
    </Stack>
  );
};
