import { CURVE_PLACES_AMOUNT, PRODUCT_KEY } from 'shared/constants';

export const validateStringLength = (value: string, maxLength: number, minLength = 0) => {
  if (value?.length > maxLength) return `Maximum Length is ${maxLength}`;
  if (value?.length < minLength) return `Minimum Length is ${minLength}`;

  return '';
};
export const validateNumberValue = (value: string | number, limitValue = true) => {
  let error = 'The value is invalid. The Value must be > 0 or empty';
  const isNan = isNaN(Number(value));

  if (isNan) return error;

  const val = value?.toString();
  error = val === '0' ? error : '';

  if (limitValue && +value > 9999) error = 'Value Exceeds Maximum Limit';

  return error;
};

const disableValidation = () => '';
const validateGroupValue = (value: string | null) => (value ? '' : 'The value is invalid. Select something');

export const validateEmptyNumberValue = (value: string | number) => {
  return !value?.toString() ? 'Input something' : '';
};

const places = [...new Array(CURVE_PLACES_AMOUNT)]
  .map((_, i) => ({
    [`place${i}`]: i == 0 ? validateEmptyNumberValue : disableValidation,
  }))
  .reduce((acc, c) => ({ ...acc, ...c }), {});

export const tableCellValidation = {
  [PRODUCT_KEY.SKU]: disableValidation,
  // [PRODUCT_KEY.SKU]: (value: string) => validateStringLength(value, 20, 1),
  [PRODUCT_KEY.DESC]: (value: string) => validateStringLength(value, 100, 2),

  [PRODUCT_KEY.BRAND]: disableValidation,
  [PRODUCT_KEY.SUBTYPE]: disableValidation,
  [PRODUCT_KEY.GROUP]: validateGroupValue,

  [PRODUCT_KEY.INLET]: validateNumberValue,
  [PRODUCT_KEY.OUTLET]: validateNumberValue,
  [PRODUCT_KEY.OPT_FACTOR1]: validateNumberValue,
  [PRODUCT_KEY.OPT_FACTOR2]: validateNumberValue,

  [PRODUCT_KEY.CLASS]: (value: string) => validateStringLength(value, 5),
  [PRODUCT_KEY.THICKNESS]: validateNumberValue,
  [PRODUCT_KEY.INT_RNL]: validateNumberValue,
  [PRODUCT_KEY.V_MAX]: validateNumberValue,
  [PRODUCT_KEY.HW_COF]: validateNumberValue,
  [PRODUCT_KEY.PIPE_DHA]: validateNumberValue,
  [PRODUCT_KEY.PIPE_DHB]: validateNumberValue,
  [PRODUCT_KEY.PIPE_DHC]: validateNumberValue,

  [PRODUCT_KEY.NOZZLE]: (value: string) => validateStringLength(value, 100),
  [PRODUCT_KEY.SWIVEL]: (value: string) => validateStringLength(value, 100),
  [PRODUCT_KEY.Q_NOM]: (value: string | number) => validateNumberValue(value, false),
  [PRODUCT_KEY.P_MIN]: validateNumberValue,
  [PRODUCT_KEY.P_MAX]: validateNumberValue,
  [PRODUCT_KEY.PRESSURE_NOMINAL_M]: validateNumberValue,
  [PRODUCT_KEY.KD]: validateNumberValue,
  [PRODUCT_KEY.EMITTER_QA]: validateNumberValue,
  [PRODUCT_KEY.EMITTER_QB]: validateNumberValue,

  [PRODUCT_KEY.SPACING]: validateNumberValue,
  [PRODUCT_KEY.QL_HR_100M]: validateNumberValue,

  [PRODUCT_KEY.ORIENT]: disableValidation,
  [PRODUCT_KEY.PRESSURE]: validateNumberValue,
  [PRODUCT_KEY.RISER]: validateNumberValue,
  [PRODUCT_KEY.BASE_DIS]: validateNumberValue,
  [PRODUCT_KEY.FLOW_RATE]: validateNumberValue,
  [PRODUCT_KEY.RANGE]: validateNumberValue,
  ...places,
};

export const VALIDATED_KEYS = [
  PRODUCT_KEY.GROUP,
  PRODUCT_KEY.INLET,
  PRODUCT_KEY.OUTLET,
  PRODUCT_KEY.OPT_FACTOR1,
  PRODUCT_KEY.OPT_FACTOR2,
  PRODUCT_KEY.CLASS,
  PRODUCT_KEY.THICKNESS,
  PRODUCT_KEY.INT_RNL,
  PRODUCT_KEY.V_MAX,
  PRODUCT_KEY.HW_COF,
  PRODUCT_KEY.PIPE_DHA,
  PRODUCT_KEY.PIPE_DHB,
  PRODUCT_KEY.PIPE_DHC,
  PRODUCT_KEY.Q_NOM,
  PRODUCT_KEY.P_MIN,
  PRODUCT_KEY.P_MAX,
  PRODUCT_KEY.PRESSURE_NOMINAL_M,
  PRODUCT_KEY.KD,
  PRODUCT_KEY.EMITTER_QA,
  PRODUCT_KEY.EMITTER_QB,
  PRODUCT_KEY.SPACING,
  PRODUCT_KEY.QL_HR_100M,
  PRODUCT_KEY.PRESSURE,
  PRODUCT_KEY.RISER,
  PRODUCT_KEY.BASE_DIS,
  PRODUCT_KEY.FLOW_RATE,
  PRODUCT_KEY.RANGE,
];
