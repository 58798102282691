import { FC } from 'react';
import { Stack } from '@mui/material';
import { TableProductInfo } from 'shared/models';
import { TranslationItem } from './TranslationItem';
import { EditTranslation } from './EditTranslation';
import { SearchData, useProductTranslations } from '../hooks';

interface Props {
  productItem: TableProductInfo;
  products: TableProductInfo[];
  isSelected: boolean;
  selectedLanguageId: string;
  descriptionSearchData: SearchData;
  translationSearchData: SearchData;
  onSelect: (id: string) => void;
  updateProduct: (updatedComponent: TableProductInfo) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const ProductTranslationItem: FC<Props> = ({
  productItem,
  products,
  isSelected,
  selectedLanguageId,
  descriptionSearchData,
  translationSearchData,
  onSelect,
  updateProduct,
  onKeyDown,
}) => {
  const {
    localProductItem,
    descriptionError,
    translationError,
    onDescriptionChange,
    onTranslationChange,
    onBlur,
    handleKeyDown,
  } = useProductTranslations({ productItem, products, selectedLanguageId, updateProduct, onKeyDown });

  return (
    <Stack
      direction="row"
      onBlur={onBlur}
      onClick={() => onSelect(productItem.id)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      sx={{
        borderBottom: '1px solid #D4D4D4',
        '&:hover': { bgcolor: 'rgba(29, 135, 69, 0.04)' },
        '&:focus': { outline: 'none' },
        ...(isSelected && { bgcolor: 'rgba(29, 135, 69, 0.08) !important' }),
      }}
    >
      {/* Description item */}
      <TranslationItem
        value={localProductItem.description}
        error={descriptionError}
        isSelected={isSelected}
        onUpdateItem={onDescriptionChange}
        searchData={descriptionSearchData}
        searchType="description"
        sx={{ borderRight: '1px solid #B2B2B2' }}
      />

      <EditTranslation
        productItem={localProductItem}
        isSelected={isSelected}
        selectedLanguageId={selectedLanguageId}
        error={translationError}
        onUpdateItem={onTranslationChange}
        searchData={translationSearchData}
      />
    </Stack>
  );
};
