import { IconButton, InputBase, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, startTransition, useEffect, useState } from 'react';
import { useAppDispatch, useCatalogSearchParams } from 'shared/hooks';
import { CellType } from 'shared/models';
import { setTableSearch, setTableSearchCurrentIdx } from 'shared/slices';
import { ArrowDown, Icon } from 'shared/ui';
import { SearchIcon } from 'shared/ui/icons/SearchIcon';

interface Props {
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
}

export const TableSearch: FC<Props> = ({ tableSearch }) => {
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();

  const { search, currentIdx, cells } = tableSearch ?? { search: '', currentIdx: 0, cells: [] };

  const [inputValue, setInputValue] = useState(search);

  const disableArrow = cells.length === 1;
  const color = disableArrow ? 'rgba(114, 114, 114, 0.7)' : 'rgba(114, 114, 114, 1)';

  useEffect(() => {
    window.addEventListener('keydown', handleEnterKeydown);
    return () => window.removeEventListener('keydown', handleEnterKeydown);
  }, []);

  const onChangeTableSearch = (value: string) => catalogId && dispatch(setTableSearch({ catalogId, value }));

  const onMoveToNewCurrentIdx = (moveTo: 'next' | 'prev') =>
    catalogId && dispatch(setTableSearchCurrentIdx({ catalogId, moveTo }));

  const handleEnterKeydown = (e: KeyboardEvent) => {
    if (e.code == 'Enter') {
      e.preventDefault();
      onMoveToNewCurrentIdx('next');
    }
  };

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;
    setInputValue(value);

    startTransition(() => {
      onChangeTableSearch(value);
    });
  };

  const handleClearInputValue = () => {
    setInputValue('');

    startTransition(() => {
      onChangeTableSearch('');
    });
  };

  return (
    <Stack direction="row" alignItems="center">
      <InputBase
        value={inputValue}
        onChange={handleChangeInputValue}
        placeholder="Find..."
        startAdornment={<SearchIcon sx={{ fontSize: 16, mr: 1 }} />}
        endAdornment={
          cells.length ? (
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: color }}>{`(${currentIdx + 1}/${cells.length})`}</Typography>

              <IconButton
                onClick={() => onMoveToNewCurrentIdx('next')}
                disabled={disableArrow}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0 }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
              <IconButton
                onClick={() => onMoveToNewCurrentIdx('prev')}
                disabled={disableArrow}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0, rotate: '180deg' }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
            </Stack>
          ) : null
        }
        sx={{
          position: 'relative',
          p: 1,
          width: 360,
          height: 40,
          borderRadius: '6px',
          border: '1px solid #DDE0E6',
          bgcolor: '#FFF',
          zIndex: 2,
          '& .MuiInputBase-input': {
            p: 0,
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#757575',
            opacity: 1,
          },
        }}
      />

      {inputValue && (
        <IconButton onClick={handleClearInputValue} sx={{ width: 40, height: 40 }}>
          <Icon path="products-view/search-close" />
        </IconButton>
      )}
    </Stack>
  );
};
