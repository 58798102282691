import { ChangeEvent, FC, useEffect, useState } from 'react';
import { TableProductInfo } from 'shared/models';
import { useAppSelector } from 'shared/hooks';
import { selectCatalogLanguages } from 'shared/slices';
import { TranslationItem } from './TranslationItem';
import { SearchData } from '../hooks';

interface Props {
  productItem: TableProductInfo;
  isSelected: boolean;
  selectedLanguageId: string;
  error: string | null;
  searchData: SearchData;
  onUpdateItem: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const EditTranslation: FC<Props> = ({
  productItem,
  isSelected,
  selectedLanguageId,
  error,
  searchData,
  onUpdateItem,
}) => {
  const [value, setValue] = useState('');
  const languages = useAppSelector(selectCatalogLanguages);

  useEffect(() => {
    const stringResources = productItem.stringResources;

    if (!stringResources) {
      setValue('');
      return;
    }

    const translation = stringResources.find((sr) => sr.languageId === selectedLanguageId);

    setValue(translation?.value ?? '');
  }, [selectedLanguageId]);

  const isHebrew = languages.find((l) => l.id === selectedLanguageId)?.culture === 'he-IL';

  return (
    <TranslationItem
      value={value}
      error={error}
      isSelected={isSelected}
      onUpdateItem={(e) => {
        setValue(e.target.value);
        onUpdateItem(e);
      }}
      searchData={searchData}
      searchType="translation"
      withRTLPropvider
      isHebrew={isHebrew}
    />
  );
};
