import { FC, RefObject } from 'react';
import { Box, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ItemsBox } from 'shared/ui';
import { LanguageData, TableProductInfo } from 'shared/models';
import { ProductTranslationItem } from './components';
import { TranslationsSearchInput } from './components/TranslationsSearchInput';
import { LanguageSelect } from './components/LanguageSelect';
import { useCatalogTranslationsTable, useTranslationsSearch } from './hooks';

export const BoxHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '12px',
  width: '50%',
  height: '48px',
}));

interface Props {
  isLanguagesLoading: boolean;
  isProductsLoading: boolean;
  languages: LanguageData[];
  isGroupSelected: boolean;
  selectedTranslationId: string;
  selectedLanguageId: string;
  products: TableProductInfo[];
  onLanguageChange: (langId: string) => void;
  updateProduct: (updatedComponent: TableProductInfo) => void;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  selectedItemRef: RefObject<HTMLElement> | null;
}

export const CatalogTranslationsTable: FC<Props> = ({
  isLanguagesLoading,
  isProductsLoading,
  isGroupSelected,
  selectedLanguageId,
  selectedTranslationId,
  languages,
  products,
  onLanguageChange,
  updateProduct,
  onSelect,
  onKeyDown,
  selectedItemRef,
}) => {
  const { t } = useTranslation();

  const { areSearchInputsShown, descriptions, translations, currentProducts, onUpdateProduct } =
    useCatalogTranslationsTable({
      isProductsLoading,
      selectedLanguageId,
      products,
      updateProduct,
    });

  const {
    onSearchChange: onDescriptionSearchChange,
    searchData: descriptionSearchData,
    goToMatch: goToDescriptionMatch,
  } = useTranslationsSearch('description', descriptions);

  const {
    onSearchChange: onTranslationSearchChange,
    searchData: translationSearchData,
    goToMatch: goToTranslationMatch,
  } = useTranslationsSearch('translation', translations);

  return (
    <ItemsBox sx={{ minWidth: 1280 }} id="scrollable-translations-container">
      <Stack
        direction="row"
        sx={{ position: 'sticky', top: 0, zIndex: 2, bgcolor: '#FFF', borderBottom: '0.5px solid #D4D4D4' }}
      >
        <BoxHeaderContainer sx={{ borderRight: '0.5px solid #B2B2B2' }}>
          <Typography sx={{ fontWeight: 600 }}>{t('Description')}</Typography>

          {isProductsLoading && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress size={15} color="primary" />
            </Box>
          )}

          {areSearchInputsShown && (
            <TranslationsSearchInput
              searchQuery={descriptionSearchData.searchQuery}
              searchData={descriptionSearchData}
              onChange={onDescriptionSearchChange}
              goToMatch={goToDescriptionMatch}
            />
          )}
        </BoxHeaderContainer>

        <BoxHeaderContainer>
          <LanguageSelect
            value={selectedLanguageId}
            onChange={(e) => onLanguageChange(e.target.value as string)}
            disabled={!languages?.length}
            language={selectedLanguageId}
            languages={languages}
            isLanguagesLoading={isLanguagesLoading}
          />

          {areSearchInputsShown && (
            <TranslationsSearchInput
              searchQuery={translationSearchData.searchQuery}
              searchData={translationSearchData}
              onChange={onTranslationSearchChange}
              goToMatch={goToTranslationMatch}
            />
          )}
        </BoxHeaderContainer>
      </Stack>

      {!isProductsLoading &&
        (isGroupSelected ? (
          products.map((product) => {
            const isSelected = product.id === selectedTranslationId;

            return (
              <Box key={product.id} ref={isSelected ? (selectedItemRef as RefObject<HTMLDivElement>) : null}>
                <ProductTranslationItem
                  productItem={product}
                  isSelected={isSelected}
                  updateProduct={onUpdateProduct}
                  selectedLanguageId={selectedLanguageId}
                  onSelect={() => onSelect(product.id)}
                  onKeyDown={onKeyDown}
                  products={currentProducts}
                  descriptionSearchData={descriptionSearchData}
                  translationSearchData={translationSearchData}
                />
              </Box>
            );
          })
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: 120, bgcolor: '#F2F4F7' }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: 600, letterSpacing: '0.18px', color: '#242731' }}>
              {t('Please select a group to view product list')}
            </Typography>
          </Stack>
        ))}
    </ItemsBox>
  );
};
