import { FC, RefObject } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddItemButton, ItemsBox } from 'shared/ui';
import { SubTypeItem } from 'entities/SubType/SubTypeItem';
import { BoxHeader } from './GroupsSubtypesBrands';
import { NEW_ITEM_ID } from 'widgets/CatalogManager/hooks';
import { SubType } from 'shared/models';

type Props = {
  isPipeType: boolean;
  isViewer: boolean;
  selectedIDs: { subtype: string; group: string; brand: string };
  disabled: boolean;
  loadingIDs: string[];
  subtypeItems: SubType[];
  onChangeSelectedIDs: (id: string) => void;
  onCreateSubtype: (subtypeName: string) => Promise<void>;
  onDeleteSubtype: (id: string) => Promise<void>;
  onChangeSubtype: (
    id: string,
    updateData: {
      subtypeName: string;
      isLateral: boolean;
      isSubMain: boolean;
      isMainPipe: boolean;
    }
  ) => Promise<void>;
  setActiveBox: (type: 'subtype') => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => void;
  selectedItemRef: RefObject<HTMLElement>;
};

export const SubtypesTable: FC<Props> = ({
  isPipeType,
  isViewer,
  selectedIDs,
  disabled,
  loadingIDs,
  subtypeItems,
  onChangeSelectedIDs,
  onCreateSubtype,
  onDeleteSubtype,
  onChangeSubtype,
  setActiveBox,
  onKeyDown,
  selectedItemRef,
}) => {
  const { t } = useTranslation();

  return (
    <ItemsBox
      onClick={() => setActiveBox('subtype')}
      sx={{ width: isPipeType ? 536 : 240, minWidth: isPipeType ? 536 : 240 }}
    >
      <Box sx={{ bgcolor: '#fff', position: 'sticky', top: 0, zIndex: 2 }}>
        {isPipeType ? (
          <Stack direction="row">
            <BoxHeader noWrap sx={{ width: '45%', borderRight: '1px solid lightgrey' }}>
              {t('Subtypes')}
            </BoxHeader>

            <BoxHeader noWrap sx={{ width: '55%' }}>
              {t('Include in')}
            </BoxHeader>
          </Stack>
        ) : (
          <BoxHeader>{t('Subtypes')}</BoxHeader>
        )}

        {!isViewer && (
          <AddItemButton
            insideTable
            isOpenInput={selectedIDs.subtype === NEW_ITEM_ID.subtype}
            onOpen={() => onChangeSelectedIDs(NEW_ITEM_ID.subtype)}
            onClose={() => onChangeSelectedIDs('')}
            onConfirm={onCreateSubtype}
            disabled={disabled}
            title={'+ ' + t('Create Subtype')}
            isLoading={loadingIDs.includes(NEW_ITEM_ID.subtype)}
            errorText={'Subtype name must be min 3 characters and max 50'}
          />
        )}
      </Box>

      {subtypeItems.map((s) => (
        <Box ref={s.id === selectedIDs.subtype ? (selectedItemRef as RefObject<HTMLDivElement>) : null} key={s.id}>
          <SubTypeItem
            subTypeInfo={s}
            onDelete={() => onDeleteSubtype(s.id)}
            onChangeSubtype={onChangeSubtype}
            isSelected={s.id === selectedIDs.subtype}
            onSelect={() => {
              onChangeSelectedIDs(s.id);
              setActiveBox('subtype');
            }}
            isPipeType={isPipeType}
            isLoading={loadingIDs.includes(s.id)}
            disabled={disabled}
            onKeyDown={onKeyDown}
          />
        </Box>
      ))}
    </ItemsBox>
  );
};
