import { ChangeEvent, FC, KeyboardEvent } from 'react';
import { CircularProgress, IconButton, InputBase, Stack, Typography } from '@mui/material';
import { ErrorTooltip, Icon } from 'shared/ui';

type Props = {
  name: string;
  nameValue: string;
  isNameEditing: boolean;
  isNameLoading: boolean;
  nameError: boolean;
  isSelected?: boolean;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSaveName: () => void;
  onCancelEditing: () => void;
  onOpenNameEditing: () => void;
  handleSelect: () => void;
  onToggleConfirm: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export const GroupNameCell: FC<Props> = ({
  name,
  nameValue,
  isNameEditing,
  isNameLoading,
  nameError,
  isSelected,
  onChangeValue,
  handleSaveName,
  onCancelEditing,
  onOpenNameEditing,
  handleSelect,
  onToggleConfirm,
  onKeyDown,
}) => {
  return (
    <Stack
      onClick={handleSelect}
      onKeyDown={onKeyDown}
      tabIndex={0}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        py: 1,
        pl: 1.5,
        pr: 2.5,
        height: '100%',
        width: '34%',
        minWidth: '34%',
        borderRight: '1px solid lightgrey',
        outline: 'none',
        '&:focus': { outline: 'none' },
        '&:hover': {
          '& .icons': {
            display: isNameLoading ? 'none' : 'flex',
          },
        },
      }}
    >
      {isNameEditing ? (
        <>
          <ErrorTooltip
            open={nameError}
            title={'Group name must be between 3 and 50 characters.'}
            arrow
            sx={{ width: '100%' }}
          >
            <InputBase
              value={nameValue}
              onChange={onChangeValue}
              fullWidth
              autoFocus
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter') {
                  handleSaveName();
                }
              }}
              sx={{ p: 0, input: { p: 0, color: 'primary.main' } }}
            />
          </ErrorTooltip>

          <Stack direction="row" sx={{ pl: 1 }}>
            <IconButton onClick={onCancelEditing} sx={{ p: 0 }}>
              <Icon path="circle-btn-close" />
            </IconButton>

            <IconButton onClick={handleSaveName} sx={{ p: 0 }}>
              <Icon path="circle-btn-done" />
            </IconButton>
          </Stack>
        </>
      ) : (
        <>
          <Typography
            noWrap
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              textOverflow: 'unset',
              overflowX: 'auto',
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'info.dark',
                borderRadius: '30px',
              },
              '&::-webkit-scrollbar': {
                height: '2px',
                backgroundColor: 'info.light',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'info.light',
              },
              '&::-webkit-scrollbar-corner': {
                backgroundColor: 'info.light',
                background: 'info.light',
              },
            }}
          >
            {name}
          </Typography>

          {isNameLoading && <CircularProgress size={15} color="primary" />}

          <Stack
            className="icons"
            direction="row"
            spacing={1}
            sx={{ display: isSelected ? 'flex' : 'none', pl: 1 }}
          >
            <IconButton onClick={onOpenNameEditing} sx={{ p: 0 }}>
              <Icon path="group-edit" />
            </IconButton>

            <IconButton onClick={onToggleConfirm} sx={{ p: 0 }}>
              <Icon path="group-delete" />
            </IconButton>
          </Stack>
        </>
      )}
    </Stack>
  );
};
