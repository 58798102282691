import { Tab, Tabs } from '@mui/material';
import { FC, startTransition, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/hooks';
import { CATALOG_SPEC, CATALOG_TYPE } from 'shared/constants';

import { useParams } from 'react-router-dom';
import { changeSpecification } from 'shared/slices';

type Props = {
  disabled: boolean;
  type: string;
  specification: number;
  isDistributionCurveType: boolean;
};

export const TableTabs: FC<Props> = ({ disabled, type, specification, isDistributionCurveType }) => {
  const { id: catalogId } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [tabValue, setTabValue] = useState(specification);

  const tabs = useMemo(() => {
    const generalTabs = [{ label: t('general'), value: CATALOG_SPEC.GENERAL }];
    const pipeIntegralTabs =
      type === CATALOG_TYPE.PIPE || type === CATALOG_TYPE.INTEGRAL
        ? [{ label: t('pipe_spec'), value: CATALOG_SPEC.PIPE }]
        : [];
    const emitterTabs =
      type === CATALOG_TYPE.EMITTER ? [{ label: t('emitter_spec'), value: CATALOG_SPEC.EMITTER }] : [];
    const integralTabs =
      type === CATALOG_TYPE.INTEGRAL ? [{ label: t('integral_emitter_spec'), value: CATALOG_SPEC.INTEGRAL }] : [];
    const DCTabs = isDistributionCurveType
      ? [
          { label: t('place 0-25'), value: CATALOG_SPEC.PLACE_0_26 },
          { label: t('place 26-52'), value: CATALOG_SPEC.PLACE_26_52 },
        ]
      : [];

    return [...generalTabs, ...pipeIntegralTabs, ...emitterTabs, ...integralTabs, ...DCTabs];
  }, [type, isDistributionCurveType]);

  const handleChangeTabValue = (_: React.SyntheticEvent, index: CATALOG_SPEC) => {
    setTabValue(index);
    startTransition(() => {
      catalogId && dispatch(changeSpecification({ catalogId, index }));
    });
  };

  return (
    <Tabs
      value={tabValue ?? 0}
      onChange={handleChangeTabValue}
      TabIndicatorProps={{
        sx: {
          top: 0,
          bottom: 'unset',
          height: '2px',
          backgroundColor: 'primary.main',
        },
      }}
      sx={{ minHeight: '0px', '& .MuiTabs-flexContainer': { mt: 0.5, gap: 1.5 } }}
    >
      {tabs.map(({ label, value }) => (
        <Tab
          key={label}
          label={label}
          value={value}
          disabled={disabled}
          sx={{
            px: 1,
            py: 0,
            minHeight: 'unset',
            color: '#667085',
            fontSize: 18,
            fontWeight: 400,
            lineHeight: '130%',
            letterSpacing: '0.18px',
            textTransform: 'none',
            '&.Mui-selected': {
              color: '#000',
              fontWeight: 600,
            },
            transition: 'color 0.3s ease, font-weight 0.3s ease',
          }}
        />
      ))}
    </Tabs>
  );
};
