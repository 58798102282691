import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { Organization } from 'shared/models';
import { HeadCell } from './HeadCell';
import { useTranslation } from 'react-i18next';
import { OrganizationItem, OrgRow } from 'entities/Organization/components';
import { selectFlattedOrg } from '../../../shared/slices';
import { OrgWithActiveUsersCount } from '..';

const NoOrgsText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '16px',
  color: theme.palette.secondary.light,
  width: '100%',
  textAlign: 'center',
  marginTop: '30px',
  fontWeight: 600,
}));

interface Props {
  orgs: Organization[];
  searchValue: string;
}

export const OrganizationsTable: FC<Props> = ({ orgs, searchValue }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const flattedOrgs = useAppSelector(selectFlattedOrg);
  const [searchedOrgs, setSearchedOrgs] = useState<Organization[]>([]);

  const renderOrgs = (orgs: OrgWithActiveUsersCount[], depth = 0): ReactNode => {
    return orgs.map((org, i, arr) => {
      if (org.subOrganizations.length === 0) {
        return <OrgRow key={org.id} org={org} depth={depth} />;
      }
      return (
        <OrganizationItem
          key={org.id}
          org={org}
          depth={depth}
          childOrgs={renderOrgs(org.subOrganizations, depth + 1)}
          isLastChild={arr.length - 1 === i}
        />
      );
    });
  };

  const showSearchedOrgs = () => {
    const searched = flattedOrgs.filter(
      (org) =>
        org.organizationName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        org.admins.some((admin) => admin.toLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim()))
    );

    const uniqueOrganizations = searched.reduce<Organization[]>((accumulator, current) => {
      if (!accumulator.find((item) => item.organizationId === current.organizationId)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    setSearchedOrgs(uniqueOrganizations);
  };

  useEffect(() => {
    showSearchedOrgs();
  }, [searchValue]);

  return (
    <Box sx={{ height: { xs: 'calc(100vh - 238px)', lg: 'calc(100vh - 205px)' } }}>
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            width: '35%',
            pl: '30px',
          }}
        >
          <HeadCell>{t('org_name')}</HeadCell>
        </Box>
        <Box sx={{ width: '20%' }}>
          <HeadCell>{t('org_admin')}</HeadCell>
        </Box>
        <Box sx={{ width: '10%', minWidth: '100px' }}>
          <HeadCell>{t('sub_org')}</HeadCell>
        </Box>
        <Box sx={{ width: '10%', minWidth: '100px' }}>
          <HeadCell>{t('active_users')}</HeadCell>
        </Box>

        <Stack sx={{ width: '10%' }} alignItems="center">
          <HeadCell last>{t('actions')}</HeadCell>
        </Stack>
      </Stack>

      <Box
        sx={{
          height: 'calc(100% - 60px)',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.primary.main,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: palette.info.dark,
            borderRadius: '4px',
          },
        }}
      >
        {searchValue ? (
          searchedOrgs.length > 0 ? (
            searchedOrgs.map((org) => <OrgRow key={org.id} org={org} depth={0} searched />)
          ) : (
            <NoOrgsText>{t('organizations_no_organizations')}</NoOrgsText>
          )
        ) : (
          renderOrgs(orgs)
        )}
      </Box>
    </Box>
  );
};
