import { FC, useEffect, useRef } from 'react';
import { BoxProps, CircularProgress, Stack } from '@mui/material';
import { scrollStyles } from 'widgets/CatalogManager/utils';

interface Props extends BoxProps {
  handleScrollAppear?: (val: boolean) => void;
  isDisableLoading?: boolean;
}

export const ItemsBox: FC<Props> = ({ children, handleScrollAppear, isDisableLoading, onClick, sx, id }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current || !handleScrollAppear) return;

    handleScrollAppear(containerRef.current.scrollHeight > containerRef.current.clientHeight);
  }, [containerRef.current, handleScrollAppear]);

  return (
    <Stack
      id={id}
      onClick={onClick}
      sx={{
        position: 'relative',
        flexShrink: 1,
        width: '200px',
        minWidth: '200px',
        height: 'fit-content',
        maxHeight: 'calc(100svh - 236px)',
        border: '0.5px solid #D4D4D4',
        overflowX: 'hidden',
        zIndex: 2,
        ...sx,
      }}
    >
      <Stack ref={containerRef} sx={{ overflowY: 'auto', overflowX: 'hidden', ...scrollStyles }}>
        {children}
      </Stack>

      {isDisableLoading && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bgcolor: 'info.light',
            opacity: 0.65,
          }}
        >
          <CircularProgress size={25} color="primary" />
        </Stack>
      )}
    </Stack>
  );
};
