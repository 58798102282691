import { Box } from '@mui/material';
import { ProductContextMenu } from 'features';
import { FC, PropsWithChildren } from 'react';
import { useContextMenu } from 'shared/hooks';
import { CellType, ContextMenuType } from 'shared/models';

interface Props extends PropsWithChildren {
  isSelectedFullRow: boolean;
  openEditMode: (cell: CellType) => void;
}

export const ContextMenuWrapper: FC<Props> = ({ children, isSelectedFullRow, openEditMode }) => {
  const { isContextMenu, openContextMenu, closeContextMenu } = useContextMenu();

  return (
    <Box onContextMenu={openContextMenu} sx={{ height: '100%' }}>
      {children}

      <ProductContextMenu
        contextMenu={isContextMenu}
        onClose={closeContextMenu}
        isSelectedFullRow={isSelectedFullRow}
        openEditMode={openEditMode}
      />
    </Box>
  );
};
