import { ChangeEvent, FC, useMemo } from 'react';
import { Box, Stack, SxProps, TextField, Typography } from '@mui/material';
import { RtlProvider } from 'app/providers/RtlProvider';
import { ErrorMessagePopover } from './ErrorMessagePopover';
import { getDividedByRegexpText } from 'shared/lib';
import { SearchData, SearchType } from '../hooks';

type Props = {
  value?: string;
  isSelected: boolean;
  error: string | null;
  onUpdateItem: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  searchData: SearchData;
  searchType: SearchType;
  withRTLPropvider?: boolean;
  isHebrew?: boolean;
  sx?: SxProps;
};

export const TranslationItem: FC<Props> = ({
  value,
  error,
  isSelected,
  searchData: { searchQuery, currentMatchIdx, matches },
  searchType,
  isHebrew = false,
  withRTLPropvider,
  onUpdateItem,
  sx,
}) => {
  const highlightColor = useMemo(() => {
    if (!value || !matches.length) return '';

    const matchIndex = matches.findIndex((m) => m === value);
    if (matchIndex === -1) return '';

    // current match
    if (matchIndex === currentMatchIdx) return 'rgba(255, 150, 50, 0.8)';
    // regular match
    return 'rgba(253, 221, 86, 0.7)';
  }, [value, matches, currentMatchIdx]);

  const highLightedTextParts = useMemo(() => {
    if (!highlightColor || !value) return null;
    return getDividedByRegexpText(searchQuery, value);
  }, [highlightColor, searchQuery, value]);

  const translationItemContent = (
    <>
      {isSelected ? (
        <Stack direction="row" sx={{ width: '100%' }}>
          <TextField
            value={value}
            fullWidth
            onChange={onUpdateItem}
            onKeyDown={(e) => {
              if (e.key === 'Enter') (e.target as HTMLInputElement).blur();
            }}
            sx={{
              input: {
                p: 0,
                height: '100%',
                fontSize: 16,
                fontWeight: 400,
                color: 'primary.main',
                ...(isHebrew && { fontFamily: 'Helvetica' }),
              },
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
          />

          {!!error && <ErrorMessagePopover error={error} />}
        </Stack>
      ) : (
        <Typography
          id={`${value}-${searchType}`}
          noWrap
          sx={{
            lineHeight: '20px',
            ...(isHebrew && { fontFamily: 'Helvetica' }),
            span: {
              backgroundColor: highlightColor,
            },
          }}
        >
          {highLightedTextParts && highLightedTextParts.length
            ? highLightedTextParts.map((w, i) => (i === 1 ? <span key={i}>{w}</span> : w))
            : value}
        </Typography>
      )}
    </>
  );

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        justifyContent: 'flex-start',
        padding: '8px 20px 8px 12px',
        width: '50%',
        height: 38,
        borderRadius: 0,
        border: '2px solid transparent',
        color: '#242731',
        // textTransform: 'capitalize',
        outline: 'none',
        '&:focus': { outline: 'none' },
        ...(error && { borderWidth: 2, borderColor: 'red' }),
        ...sx,
      }}
    >
      {withRTLPropvider ? (
        <RtlProvider enable={isHebrew}>{translationItemContent}</RtlProvider>
      ) : (
        translationItemContent
      )}
    </Box>
  );
};
