import { FC } from 'react';
import { MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FilterSelect } from 'shared/ui';
import { separateByUpperCase } from 'shared/lib';
import { SubType } from 'shared/models';

type Props = {
  isNoTypes: boolean;
  type: string;
  catalogTypes: string[];
  subtypeItems: SubType[];
  subtypeFilterValue?: string;
  onChangeManageType: (type: string) => void;
  onChangeSelectedIDs: (id: string, type: 'subtype') => void;
};

export const Filters: FC<Props> = ({
  isNoTypes,
  type,
  catalogTypes,
  onChangeManageType,
  subtypeFilterValue,
  onChangeSelectedIDs,
  subtypeItems,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={3} sx={{ py: 3 }}>
      <FilterSelect label={t('type')} value={isNoTypes ? '' : type}>
        {catalogTypes.map((type) => (
          <MenuItem key={type} value={type} onClick={() => onChangeManageType(type)}>
            {separateByUpperCase(type)}
          </MenuItem>
        ))}
      </FilterSelect>

      <FilterSelect
        label={t('subtype')}
        value={subtypeFilterValue ?? ''}
        clearFilter={() => onChangeSelectedIDs('', 'subtype')}
        allowEmpty
      >
        {subtypeItems.map((st) => (
          <MenuItem key={st.id} value={st.name} onClick={() => onChangeSelectedIDs(st.id, 'subtype')}>
            {st.name}
          </MenuItem>
        ))}
      </FilterSelect>
    </Stack>
  );
};
